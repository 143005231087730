import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: 'token',
  refreshToken: "refreshToken",
  id: 'id',
  role: 'role',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
  },
});

export const { setToken, setRefreshToken, setId, setRole } = authSlice.actions;

export default authSlice.reducer;
