/* eslint-disable */
import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import qs from "qs";
import "./scss/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./views/pages/login/Login";

import TheLayout from "./containers/TheLayout";
// import axios from "axios";
import axios from "./axios";
import Loading from "./views/pages/candidates/componants/Loading";
import { NotificationContext, UserContext } from "./UserContext";
import { ApplyJob } from "./views/pages/OpenPages/ApplyJob";
import SomeThingWentWrong from "./views/pages/candidates/componants/SomethingWentWrong";
import LandingPage from "./views/LandingPage/LandingPage";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import ViewProfile from "./views/pages/candidates/ViewProfile";
export default function App() {
  const [token, setToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState("");
  const [pageErr, setPageErr] = useState(false);

  // useEffect(() => {
  //   const handleOnlineStatusChange = () => {
  //     setPageErr(!navigator.onLine);
  //   };

  //   window.addEventListener('online', handleOnlineStatusChange);
  //   window.addEventListener('offline', handleOnlineStatusChange);

  //   setPageErr(!navigator.onLine);

  //   return () => {
  //     window.removeEventListener('online', handleOnlineStatusChange);
  //     window.removeEventListener('offline', handleOnlineStatusChange);
  //   };
  // }, [navigator.onLine]);

  // useEffect(() => {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_BASE_URL}/jwt`,
  //       {
  //         token: localStorage.getItem("refreshToken"),
  //       },
  //       {
  //         headers: {
  //           authorization: localStorage.getItem("token"),
  //           location: "login",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       if (response.data.token && response.data.user) {
  //         localStorage.setItem("role", response.data.user.role);
  //         setToken(true);
  //         setLoading(false);
  //       } else {
  //         setToken(false);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       // setPageErr(true);
  //       setLoading(false);
  //     });
  // }, []);

  let [sideBarNotification, setsideBarNotification] = useState([])
    // useEffect(()=>{
    //   axios.get(`${process.env.REACT_APP_API_BASE_URL}/notification-count`,{headers: { authorization: localStorage.getItem("token") }}).then((res)=>{
    //     setsideBarNotification(res.data);
    //   })
    // },[])

    function getNotification(){
      // axios.get(`${process.env.REACT_APP_API_BASE_URL}/notification-count`,{headers: { authorization: localStorage.getItem("token") }}).then((res)=>{
      //   setsideBarNotification(res.data);
      // })
    }

  return (
    <UserContext.Provider value={{ path, setPath }}>
      <NotificationContext.Provider value={{sideBarNotification, setsideBarNotification, getNotification}}>
        <Router>
          {!loading ? (
            !token ? (
              <Switch>
                <Route
                  path={"/openings"}
                  component={pageErr ? SomeThingWentWrong : ApplyJob}
                ></Route>

                <Route
                  path="/home"
                  component={pageErr ? SomeThingWentWrong : Login}
                />

                <Route
                  exact
                  path={"/"}
                  component={pageErr ? SomeThingWentWrong : LandingPage}
                ></Route>
                <Redirect to="/" />
              </Switch>
            ) : (
              <>
                <Switch>
                  <Route
                    path="/home"
                    render={(props) => <TheLayout {...props} />}
                  />
                  <Route
                    path={"/openings"}
                    component={pageErr ? SomeThingWentWrong : ApplyJob}
                  ></Route>
                  <Route
                    exact
                    path={"/"}
                    component={pageErr ? SomeThingWentWrong : LandingPage}
                  ></Route>

                  <Redirect to="/" />
                </Switch>
              </>
            )
          ) : (
            <Loading></Loading>
          )}
        </Router>
      </NotificationContext.Provider>
    </UserContext.Provider>
  );
}
