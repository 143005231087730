import React, { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "src/UserContext";
import { TheContent, TheSidebar, TheHeader } from "./index";
import { sideBarContext } from "src/UserContext";
const TheLayout = () => {
  // const user=useContext(UserContext)
  let [sideBar, setSideBar] = useState(window.innerWidth < 1001 ? false : true);
 
  useEffect(() => {

    const handleResize = () => {
      if (window.innerWidth < 1001) {
        setSideBar(false);
      } else {
        setSideBar(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <sideBarContext.Provider value={{sideBar,setSideBar}}>
      <div className="c-app c-default-layout">
        <TheSidebar />
        <div className="c-wrapper">
          <TheHeader />
          <div className="c-body">
            <TheContent />
          </div>
          {/* <TheFooter/> */}
        </div>
      </div>
    </sideBarContext.Provider>
  );
};

export default TheLayout;
