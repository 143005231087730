import React, { useState, useEffect } from "react";
import validation from "../../../Vaidation/validation";
// import {GrFormView} from "@react-icons/gr";

import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import axios from "../../../axios";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../../redux-toolkit/UserSlice";
import CIcon from "@coreui/icons-react";
import AdminRegister from "./AdminRegister";
import Loading from "../candidates/componants/Loading";
import SomeThingWentWrong from "../candidates/componants/SomethingWentWrong";
import Cookies from "js-cookie";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  let Confirm_validation = [
    {
      tagid: "newPassword",
      text: newPassword,
      regex_name: "free_text",
      required: true,
      min: 6,
      errmsg: "Password length must be atleast 6 characters",
    },
  ];
  let validation_input = [
    {
      tagid: "email",
      text: email,
      regex_name: "email",
      required: true,
      errmsg: "Please Enter Valid Email.",
      allow_numbers: true,
      other_chars: true,
    },
    {
      tagid: "password",
      text: password,
      regex_name: "free_text",
      required: true,
      min: 6,
      errmsg: "Password length must be atleast 6 characters",
    },
    // {
    //   tagid: "contact_id",
    //   text: opportunityDetails.contact_id,
    //   regex_name: "free_text",
    //   required: true,
    //   errmsg: "Please Select Contact Name.",
    // },
    // {
    //   tagid: "business_line",
    //   text: opportunityDetails.business_line,
    //   regex_name: "text",
    //   required: true,
    //   errmsg: "Please Select Line of Busieness.",
    // },
    // {
    //   tagid: "product_family",
    //   text: opportunityDetails.product_family,
    //   regex_name: "text",
    //   required: true,
    //   errmsg: "Please Select Product Familly.",
    // },
    // {
    //   tagid: "product",
    //   text: opportunityDetails.product,
    //   regex_name: "text",
    //   required: true,
    //   errmsg: "Please Select Product.",
    // },
    // {
    //   tagid: "service_provider",
    //   text: opportunityDetails.service_provider,
    //   regex_name: "text",
    //   required: true,
    //   errmsg: "Please Select Service Provider.",
    // },
    // {
    //   tagid: "service_opportunityid",
    //   text: opportunityDetails.service_opportunityid,
    //   regex_name: "any_number",
    //   required: true,
    //   errmsg: "Please Enter Oppo.ID.",
    // },
    // {
    //   tagid: "otc",
    //   text: opportunityDetails.otc,
    //   regex_name: "any_number",
    //   required: true,
    //   errmsg: "Please Enter One Time Charges.",
    // },
    // {
    //   tagid: "arc",
    //   text: opportunityDetails.arc,
    //   regex_name: "any_number",
    //   required: true,
    //   errmsg: "Please Enter Recurring Charges.",
    // },
    // {
    //   tagid: "months",
    //   text: opportunityDetails.months,
    //   regex_name: "any_number",
    //   required: true,
    //   errmsg: "Please Select Months.",
    // },
    // {
    //   tagid: "bill_frequency",
    //   text: opportunityDetails.bill_frequency,
    //   regex_name: "text",
    //   required: true,
    //   errmsg: "Please Select Bill Frequency.",
    // },
    // {
    //   tagid: "bill_type",
    //   text: opportunityDetails.bill_type,
    //   regex_name: "text",
    //   required: true,
    //   errmsg: "Please Select Type.",
    // },
    // {
    //   tagid: "service_id",
    //   text: opportunityDetails.service_id,
    //   regex_name: "any_number",
    //   required: true,
    //   errmsg: "Please Enter Service ID.",
    // },
    // {
    //   tagid: "summary",
    //   text: opportunityDetails.summary,
    //   required: true,
    //   regex_name: "free_text",
    //   errmsg: "Your text must be 300 characters",
    //   allow_numbers: false,
    //   other_chars: true,
    //   min: 10,
    // },
  ];
  const dispatch = useDispatch();
  const [err, setErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [exits, setExists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState("");
  const [visibile, setVisible] = useState(false);
  const [radio, setRadio] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmErr, setConfirmErr] = useState(false);
  const [reset, setReset] = useState(false);
  const [show, setShow] = useState(false);
  const [resetErr, setResetErr] = useState([]);
  const [disable, setDisplay] = useState(false);
  const [session, setSession] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      setSession(true);
    } else {
      setSession(false);
    }
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/exitsUser`, {
        headers: {
          authorization: localStorage.getItem("token"),
          location: "login",
        },
      })
      .then((res) => {
        // if (localStorage.getItem("token")) {

        // } else {
        // }
        setExists(res.data.users);
        setTimeout(() => {
          setLoading(false);
        }, 600);
      })
      .catch((err) => {
        setLoading(false);
        setErr(true);
      });
  }, []);

  const submit = (e) => {
    setEmailErr(false);
    setPasswordErr(false);
    e.preventDefault();
    if (validation(validation_input).length !== 0) {
      validation(validation_input).forEach((element) => {
        if (element.tagid === "email") setEmailErr(true);
        if (element.tagid === "password") setPasswordErr(element.errmsg);
      });
      setSession(false);
    } else {
      setEmailErr(false);
      setPasswordErr(false);
      setSession(false);
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/loginjwt`,
          {
            email: email,
            password: password,
          },
          {
            headers: {
              authorization: localStorage.getItem("token"),
              location: "jwt",
            },
          }
        )
        .then((res) => {
          if (res.data.user) {
            setVisible(false);
            setAlert("");

            setEmailErr(false);
            setPasswordErr(false);
            dispatch(
              setUserInfo({
                token: res.data.token,
                role: res.data.role,
                id: res.data.id,
              })
            );
            if (res.data.register) {
              localStorage.setItem("refreshToken", res.data.refreshToken);
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("role", res.data.role);
              localStorage.setItem("id", res.data.id);
              Cookies.set("token",res.data.token)
              Cookies.set("refresh-token",res.data.refreshToken)
              window.location.reload();
            } else {
              setDisplay(true);
              setReset(true);
            }
          } else if (res.data.password) {
            setVisible(true);
            setAlert("Invalid Credentials.");
          } else {
            setVisible(true);
            setAlert("Invalid Credentials.");
          }
        })
        .catch((err) => {});
    }
  };

  const register = (e) => {
    setEmailErr(false);
    setPasswordErr(false);
    e.preventDefault();
    // if (newPassword != confirmPassword || validation(Confirm_validation)) {
    //   setConfirmErr(true);
    //   console.log("0000", confirmErr);
    // } else {
    //   setConfirmErr(false);
    // }
    // console.log(validation(Confirm_validation));
    if (
      validation(Confirm_validation).length !== 0 ||
      newPassword !== confirmPassword
    ) {
      // validation(validation_input).forEach((element) => {
      //   // if (element.tagid === "email") setEmailErr(true);
      //   // if (element.tagid === "password") setPasswordErr(element.errmsg);
      //   setResetErr(validation(validation_input));
      // });
      setConfirmErr(true);
      setResetErr(validation(Confirm_validation));
    } else {
      setEmailErr(false);
      setConfirmErr(false);
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/resetPassword`,
          {
            email: email,
            password: password,
            newPassword,
          },
          {
            headers: {
              authorization: localStorage.getItem("token"),
              location: "jwt",
            },
          }
        )
        .then((res) => {
          setResetErr([]);
          if (res.data.user) {
            localStorage.setItem("refreshToken", res.data.refreshToken);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("role", res.data.role);
            localStorage.setItem("id", res.data.id);
            dispatch(
              setUserInfo({
                token: res.data.token,
                role: res.data.role,
                id: res.data.id,
              })
            );
            window.location.reload();
          } else if (res.data.password) {
            setVisible(true);
            setAlert("Wrong Password");
          } else {
            setVisible(true);
            setAlert("We don’t seem to have an account with that name.");
          }
        })
        .catch((err) => {
          setResetErr([]);
        });
    }
  };

  return (
    <>
      {loading ? (
        <Loading></Loading>
      ) : !err ? (
        exits.length === 0 ? (
          <AdminRegister />
        ) : (
          <div style={{ height: "100vh" }}>
            <CContainer className={"h-100"}>
              <CRow className="w-100 h-100 mx-auto d-flex justify-content-center align-items-center">
                <CCol md="5">
                  <CCardGroup>
                    <CCard className="p-4">
                      <CCardBody>
                        <CForm
                          method="post"
                          onSubmit={!reset ? submit : register}
                        >
                          <h1 className="mb-3">Login</h1>

                          {/* <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="option1"
                              defaultChecked
                              onChange={() => {
                                setRadio(true);
                                setConfirmErr(false)
                                setPassword('')
                                setConfirmPassword('')
                                setEmail('')
                                
                              }}
                            />
                            <label class="form-check-label" for="inlineRadio1">
                              Existing User
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              value="option2"
                              onChange={() => {
                                setRadio(false);
                              }}
                            />
                            <label class="form-check-label" for="inlineRadio2">
                              First Time User
                            </label>
                          </div> */}

                          {radio ? (
                            <p className="text-muted mt-3">
                              Sign In to your account
                            </p>
                          ) : (
                            <p className="text-muted mt-3">
                              Set your password with your registered Email Id
                              and login
                            </p>
                          )}
                          <CInputGroup className="mb-3 pb-0 mb-0 d-flex">
                            <div style={{ width: "100%", display: "flex" }}>
                              <CInputGroupPrepend>
                                <CInputGroupText>
                                  <CIcon name="cil-user" />
                                </CInputGroupText>
                              </CInputGroupPrepend>

                              <CInput
                                id="email"
                                invalid={emailErr}
                                type="text"
                                disabled={disable}
                                // value={email}
                                name="email"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value.toLowerCase().trim());
                                }}
                                placeholder="Email"
                                autoComplete="Email"
                              />
                            </div>

                            <small
                              style={{
                                display: emailErr ? "block" : "none",
                                color: "red",
                                width: "100%",
                                paddingLeft: 40,
                              }}
                            >
                              Enter a valid email
                            </small>
                          </CInputGroup>

                          <CInputGroup className={!radio ? "mb-3" : "mb-4"}>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                position: "relative",
                              }}
                            >
                              <CInputGroupPrepend>
                                <CInputGroupText>
                                  <CIcon name="cil-lock-locked" />
                                </CInputGroupText>
                              </CInputGroupPrepend>
                              <CInput
                                invalid={passwordErr ? true : false}
                                type={!show ? "password" : "text"}
                                value={password}
                                disabled={disable}
                                name="password"
                                id="password"
                                onChange={(e) => {
                                  setPassword(e.target.value.trimStart());
                                }}
                                placeholder="Password"
                                autoComplete="current-password"
                              />

                              {!show ? (
                                <svg
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    right: 5,
                                    top: "50%",
                                    bottom: "50%",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  fill="currentColor"
                                  class="bi bi-eye-fill ml-auto my-auto"
                                  viewBox="0 0 16 16"
                                  onClick={() => {
                                    setShow(true);
                                  }}
                                >
                                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                </svg>
                              ) : (
                                <svg
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    right: 5,
                                    top: "50%",
                                    bottom: "50%",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  fill="currentColor"
                                  class="bi bi-eye-slash-fill ml-1 my-auto"
                                  viewBox="0 0 16 16"
                                  onClick={() => {
                                    setShow(false);
                                  }}
                                >
                                  <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                  <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                </svg>
                              )}
                            </div>

                            <small
                              style={{
                                display: passwordErr ? "block" : "none",
                                color: "red",
                                width: "100%",
                                paddingLeft: 40,
                              }}
                            >
                              {passwordErr}
                            </small>
                          </CInputGroup>
                          {reset && (
                            <p className="text-muted mt-2">
                              Reset your Password and Login
                            </p>
                          )}
                          {reset && (
                            <CInputGroup className="mb-4">
                              <div style={{ width: "100%", display: "flex" }}>
                                <CInputGroupPrepend>
                                  <CInputGroupText>
                                    <CIcon name="cil-lock-locked" />
                                  </CInputGroupText>
                                </CInputGroupPrepend>
                                <CInput
                                  // invalid={passwordErr ? true : false}
                                  type="password"
                                  value={newPassword}
                                  name="confirm-password"
                                  id="confirm-password"
                                  onChange={(e) => {
                                    setNewPassword(e.target.value);
                                  }}
                                  placeholder="Enter New Password"
                                  autoComplete="current-password"
                                />
                              </div>

                              {resetErr.map((obj) => {
                                if (obj.tagid === "newPassword") {
                                  return <Err data={{ err: obj.errmsg }}></Err>;
                                } else {
                                  return null;
                                }
                              })}
                            </CInputGroup>
                          )}
                          {reset && (
                            <CInputGroup className="mb-4">
                              <div style={{ width: "100%", display: "flex" }}>
                                <CInputGroupPrepend>
                                  <CInputGroupText>
                                    <CIcon name="cil-lock-locked" />
                                  </CInputGroupText>
                                </CInputGroupPrepend>
                                <CInput
                                  invalid={confirmErr ? true : false}
                                  type="password"
                                  value={confirmPassword}
                                  name="confirm-password"
                                  id="confirm-password"
                                  onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                    if (e.target.value !== newPassword) {
                                      setConfirmErr(true);
                                    } else {
                                      setConfirmErr(false);
                                    }
                                  }}
                                  placeholder="Confirm New Password"
                                  autoComplete="current-password"
                                />
                              </div>

                              {confirmErr && (
                                <small
                                  style={{
                                    display: "block",
                                    color: "red",
                                    width: "100%",
                                  }}
                                >
                                  Password does not match
                                </small>
                              )}
                            </CInputGroup>
                          )}
                          <CAlert
                            style={{ display: visibile ? "flex" : "none" }}
                            color="danger"
                          >
                            {alert}
                          </CAlert>
                          {session && (
                            <CAlert color="warning">
                              Oops! Your Session Has Expired.Please Login
                            </CAlert>
                          )}
                          <CRow>
                            <CCol xs="6">
                              <CButton
                                // onClick={() => submit()}
                                type="submit"
                                color="primary"
                                className="px-4"
                              >
                                Login
                              </CButton>
                            </CCol>
                          </CRow>
                        </CForm>
                      </CCardBody>
                    </CCard>
                  </CCardGroup>
                </CCol>
              </CRow>
            </CContainer>
          </div>
        )
      ) : (
        <SomeThingWentWrong />
      )}
    </>
  );
};

export default Login;
let style = {
  color: "red",
  width: "100%",
  display: "block",
};
function Err(props) {
  const [err, setErr] = useState(props.data.err);
  return <small style={style}>{err}</small>;
}
