import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import "./style.css";
import trophy from "./images/trophy-line.svg";
import empathize from "./images/empathize-line.svg";
import scale from "./images/scales-fill.svg";
export default function CoreValues() {
  return (
    <CRow className={"bg-primary mx-auto"}>
      <CCol xs="12" className={" text-center my-4 "}>
        <p className="h3 Goldman">Core Values</p>
      </CCol>
      <CRow className={" d-flex justify-content- m-0 w-100" } style={{color:"black"}}>
        <CCard  className={"col-10 col-sm-7 col-md-3 mx-auto ml-md-auto"}>
          <CCardBody className={"text-center"} style={{color:"black"}}>
            <img src={trophy}></img>
            <h6 className="Nunito  font-weight-bold mt-2">Creativity, Invention and Innovation</h6>
            <small className="Nunito ">
              Every aspect of our customer’s business process and operation is a
              candidate for innovation.
            </small>
          </CCardBody>
        </CCard>

        <CCard  className={"col-10 col-sm-7 col-md-3 mx-auto"}>
          <CCardBody className={"text-center"} style={{color:"black"}}>
            <img src={scale}></img>
            <h6 className="Nunito  font-weight-bold mt-2">Honesty, Integrity and Business Ethics</h6>
            <small className="Nunito ">
              Our policy is to inculcate Honesty, Integrity and Business Ethics
              into all aspects of our business functioning.
            </small>
          </CCardBody>
        </CCard>

        <CCard  className={"col-10 col-sm-7 col-md-3 mx-auto mr-md-auto"}>
          <CCardBody className={" text-center"} style={{color:"black"}}>
            <img src={empathize}></img>
            <h6 className="Nunito  font-weight-bold mt-2">Trust and Respect</h6>
            <small className="Nunito">
              We believe, open & transparent forms of communication are
              essential building blocks for establishing trust.
            </small>
          </CCardBody>
        </CCard>
      </CRow>
    </CRow>
  );
}
