import axios from "axios";

const instance = axios.create();
const noInterceptor = axios.create();
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
        noInterceptor
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/refreshToken`,
            {},
            {
              headers: {
                authorization: localStorage.getItem("refreshToken"),
                location: "refresh",
              },
            }
          )
          .then((response) => {
            localStorage.setItem("token", response.data.token);
            if ( response.status != 500 && response.status != 404 ) {
              window.location.reload();
            }
          })
          .catch((err) => {
            if(err.code!='ERR_NETWORK'){
              localStorage.setItem("refreshToken", "");
              localStorage.removeItem("token");
            }
            window.location.reload();
          });
  }
);
export default instance;
