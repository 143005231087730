import swal from "sweetalert";
import axios from "../../../../axios";
import { Accordion, Button, Card, Dropdown, Modal } from "react-bootstrap";
import { CCard, CCardBody, CCol, CLabel, CRow } from "@coreui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import AceEditor from "react-ace";
export default function ViewStatus(props) {
  const [show, setShow] = useState(false);
  const [answer, setAnswer] = useState();
  const [testStartDate, setTestStartDate] = useState();
  const [userStart, setUserStart] = useState();
  const [testEndDate, setTestEndDate] = useState();
  const [userEnd, setuserEnd] = useState();
  const [questionId, SetQuestionId] = useState("");
  const [duration, setDuration] = useState(0);
  const [evaluation, setEvaluation] = useState();
  const [viewLoad, setViewLoad] = useState(false);
  const [windowChange, setWindowChange] = useState();
  const [testStatus, setTestStatus] = useState("");
  const [test, setTest] = useState({});
  let status = ["None", "Hold", "Reject", "Select"];
  useEffect(() => {
    setEvaluation(props.evaluation);
  }, [props.evaluation]);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setViewLoad(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/getTestFromTestPortal`,
        {
          testId: evaluation.testId,
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
            location: "login",
          },
        }
      )
      .then((response) => {
        setAnswer(response.data.Test.userAnswer);
        setDuration(response.data.Test.userTimeOut);
        setTestStartDate(response.data.Test.userTestCreatedTime);
        setTestEndDate(response.data.Test.userTestExpireTime);
        setUserStart(response.data.Login.userTestStartTimeLogin);
        setuserEnd(response.data.Login.userTestEndTimeLogin);
        setWindowChange(response.data.Test.userWindowChangeCount);
        setTestStatus(response.data.Test.userStatus);
        setTest(response.data.Test);
        setViewLoad(false);
        setShow(true);
      });
  };
  function changeStatus(status) {
    swal({
      title: "Are you sure?",
      text: "Are you sure about changing test status",
      type: "warning",
      icon: "warning",
      dangerMode: true,
      buttons: ["No", "Yes"],
      dangerMode: true,
      closeOnClickOutside: false,
    }).then((ok) => {
      if (ok) {
        axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/testPortal/changeStatus`,
            {
              idEmail: evaluation.testId,
              status,
              test,
            },
            {
              headers: {
                authorization: localStorage.getItem("token"),
                location: "login",
              },
            }
          )
          .then((res) => {
            setTestStatus(status);
            swal({
              icon: "success",
              text: "  ",
              title: "Status changed",
              timer: 1500,
              buttons: false,
            });
          });
      }
    });
  }
  return (
    <>
      <Button size="sm" className="bg-gradient-warning w-100" onClick={handleShow}>
        {" "}
        {viewLoad ? (
          <span
            class="spinner-border spinner-border-sm ml-2"
            role="status"
            aria-hidden="true"
          ></span>
        ):"View Test"}
      </Button>

      <Modal
        centered
        size="lg"
        show={show}
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRow>
            <CCol xs="12" className={"my-2"}>
              <Accordion
                className="mb-0 pb-0 w-100"
                style={{ maxWidth: "100%" }}
              >
                <Card style={{ maxWidth: "100%" }} className="p-0 m-0">
                  <Accordion.Toggle
                    style={{ cursor: "pointer", maxWidth: "100%" }}
                    as={Card.Header}
                    eventKey={"0"}
                  >
                    {evaluation?.Question?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse
                    style={{ maxWidth: "100%" }}
                    eventKey={"0"}
                  >
                    <Card.Body style={{ maxWidth: "100%" }}>
                      <p id={"0"}>
                        <pre style={{whiteSpace:"pre-line"}}>{unescape(evaluation?.Question?.q)}</pre>{" "}
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </CCol>
            <CCol xs="12" className={"my-2"}>
              <Accordion
                className="mb-0 pb-0 w-100"
                style={{ maxWidth: "100%" }}
              >
                <Card style={{ maxWidth: "100%" }} className="p-0 m-0">
                  <Accordion.Toggle
                    style={{ cursor: "pointer", maxWidth: "100%" }}
                    as={Card.Header}
                    eventKey={"0"}
                  >
                    Answer
                  </Accordion.Toggle>
                  <Accordion.Collapse
                    style={{ maxWidth: "100%" }}
                    eventKey={"0"}
                  >
                    <Card.Body style={{ maxWidth: "100%" }}>
                      {/* <AceEditor
                        style={{ maxHeight: "400px" }}
                        className="w-100"
                        fontSize={14}
                        value={unescape(answer)}
                        mode="javascript"
                        theme="twilight"
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{ $blockScrolling: true }}
                      /> */}
                      {/* ,<p id={"0"}>{unescape(answer)}</p> */}

                      <div
                        style={{
                          width: "100%",
                          height: "400px",
                          overflowY: "scroll",
                        }}
                      >
                        <pre style={{whiteSpace:"pre-line"}}>{unescape(answer)}</pre>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </CCol>

            <CCol xs="12" sm="6" md="3">
              <CLabel>Duration</CLabel>
              <CCard className={""}>
                <CCardBody className={"py-2"}>
                  {duration <= 10
                    ? duration + " " + "Days"
                    : duration + " " + "Mins"}
                </CCardBody>
              </CCard>
            </CCol>
            <CCol xs="12" sm="6" md="3" style={{ color: "black" }}>
              <CLabel>Test Start Date</CLabel>
              <CCard className={""}>
                <CCardBody className={"py-2"}>
                  {moment(testStartDate).format("DD MMM YYYY")}
                </CCardBody>
              </CCard>
            </CCol>
            <CCol xs="12" sm="6" md="3" style={{ color: "black" }}>
              <CLabel>Test End Date</CLabel>

              <CCard className={""}>
                <CCardBody className={"py-2"}>
                  {moment(testEndDate).format("DD MMM YYYY ")}
                </CCardBody>
              </CCard>
            </CCol>
            <CCol xs="12" sm="6" md="3" style={{ color: "black" }}>
              <CLabel>Test Status</CLabel>
              <Dropdown className="w-100 p-0 m-0">
                <Dropdown.Toggle
                  variant={
                    testStatus == "None"
                      ? "secondary"
                      : testStatus == "Select"
                      ? "success"
                      : testStatus == "Reject"
                      ? "danger"
                      : "warning"
                  }
                  className="w-100 card-body py-2 m-0"
                  id="dropdown-basic"
                >
                  {testStatus}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {status.map((status) => {
                    if (status != testStatus) {
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            changeStatus(status);
                          }}
                        >
                          {status}
                        </Dropdown.Item>
                      );
                    }
                  })}
                  {/* <Dropdown.Item>Reject</Dropdown.Item>
                    <Dropdown.Item>Select</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </CCol>
            <CCol xs="12" sm="6" md="4" style={{ color: "black" }}>
              <CLabel>User Test Start Time</CLabel>

              <CCard className={""}>
                <CCardBody className={"py-2"}>
                  {userStart > 0
                    ? moment(userStart).format("DD MMM YYYY LT")
                    : "0"}
                </CCardBody>
              </CCard>
            </CCol>
            <CCol xs="12" sm="6" md="4" style={{ color: "black" }}>
              <CLabel>User Test End Time</CLabel>

              <CCard className={""}>
                <CCardBody className={"py-2"}>
                  {userEnd > 0 ? moment(userEnd).format("DD MMM YYYY LT") : "0"}
                </CCardBody>
              </CCard>
            </CCol>
            <CCol xs="12" sm="6" md="4" style={{ color: "black" }}>
              <CLabel>User Window Change Count</CLabel>

              <CCard className={""}>
                <CCardBody className={"py-2"}>{windowChange}</CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
              variant="primary"
              onClick={() => {
  
              }}
            >
              Create Test
            </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
