import { CCol, CRow } from "@coreui/react";
import "./style.css";
export default function Brief() {
  return (
    <CRow className={"text-center py-5 mx-auto bg-white"} style={{color:"black"}}>
      <CCol xs='12'>
        <p className="h3 Goldman" >A Brief About Us</p>
      </CCol>
      <CCol xs="10" className={"mx-auto"}>
        <p className="Nunito">Mobigic® Technologies Private Limited is established as a Technology Company operating in India, to pursue business through innovation by utilizing advanced and latest technologies.</p>
      </CCol>
      <CCol xs="10" className={"mx-auto"}>
        <p className="Nunito">We are a startupindia registered organization working in digital transformation journey of various industry verticals.</p>
      </CCol>
      <CCol xs="10" className={"mx-auto"}>
        <p className="Nunito">MSME registered initiative approved by the Government of India. MSME # MH33D0023015</p>
      </CCol>
    </CRow>
  );
}
