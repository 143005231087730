import React, { useState } from "react";

import axios from "axios";
import validate from "src/Vaidation/validation";

export default function AdminRegister(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("admin");
  const [err, setErr] = useState([]);
  const [password, setPassword] = useState("");
  let validation_input = [
    {
      tagid: "name",
      text: name,
      regex_name: "text",
      required: true,
      errmsg: "Please Enter Valid Name.",
      allow_numbers: false,
      other_chars: true,
    },
    {
      tagid: "email",
      text: email,
      regex_name: "email",
      required: true,
      errmsg: "Please Enter Valid Email.",
      allow_numbers: true,
      other_chars: true,
    },
    {
      tagid: "password",
      text: password,
      regex_name: "free_text",
      required: true,
      min: 6,
      errmsg: "Password length must be atleast 6 characters",
    },
  ];
  const submit = (e) => {
    e.preventDefault();
    if (validate(validation_input).length > 0) {
      setErr(validate(validation_input));
    } else {
      // axios.defaults.withCredentials = true;
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/registerjwt`,
          {
            name: name,
            email: email,
            password: password,
            role: role,
          },
          {
            headers: {
              authorization: localStorage.getItem("token"),
              location: "jwt",
            },
          }
        )
        .then((res) => {
          if (res.data.password) {
            window.location.reload();
          }
        });
    }
  };

  return (
    <div className="row" style={{ marginTop: "10%" }}>
      <div
        className="card col-md-8 offset-md-2"
        style={{
          width:
            window.innerWidth < 500
              ? window.innerWidth * 0.9
              : window.innerWidth * 0.4,
        }}
      >
        <form style={{ padding: 10 }} method="post" onSubmit={submit}>
          <div className="form-group">
            <label for="exampleInputEmail1">Email address</label>
            <input
            value={email}
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
            />
            {/* <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with anyone else.
            </small> */}
            {err.map((obj) => {
              if (obj.tagid === "name") {
                return <Err data={{ err: obj.errmsg }}></Err>;
              } else {
                return null;
              }
            })}
          </div>
          <div className="form-group">
            <label for="exampleInputEmail1">Name</label>
            <input
              type="text"
              value={name}
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Name"
              onChange={(e) => {
                var splitStr = e.target.value.toLowerCase().split(" ");
                for (var i = 0; i < splitStr.length; i++) {
                  // You do not need to check if i is larger than splitStr length, as your for does that for you
                  // Assign it back to the array
                  splitStr[i] =
                    splitStr[i].charAt(0).toUpperCase() +
                    splitStr[i].substring(1);
                }
                setName(splitStr.join(" ").trimStart());
                // setName(e.target.value);
              }}
            />
            {err.map((obj) => {
              if (obj.tagid === "email") {
                return <Err data={{ err: obj.errmsg }}></Err>;
              } else {
                return null;
              }
            })}
          </div>
          <div className="form-group ">
            <label for="inputState">Role</label>
            <select id="inputState" className="form-control" value={role}>
              <option>admin</option>
            </select>
          </div>
          <div className="form-group">
            <label for="exampleInputPassword1">Password</label>
            <input
            value={password}
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value.trim())}
            />
            {err.map((obj) => {
              if (obj.tagid === "password") {
                return <Err data={{ err: obj.errmsg }}></Err>;
              } else {
                return null;
              }
            })}
          </div>

          <button
            type="submit"
            className="btn btn-primary"
            // onClick={(e) => submit(e)}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
let style = {
  color: "red",
  width: "100%",
  display: "block",
};
function Err(props) {
  const [err, setErr] = useState(props.data.err);
  return <small style={style}>{err}</small>;
}
