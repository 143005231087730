import { CCol, CRow } from "@coreui/react";
import banner from "./images/banner.svg";
export default function Banner() {
  return (
    // style={{backgroundImage:`url(${banner})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundPosition:"right",backgroundBlendMode:"revert"}}
    <CRow    className={"bg-white w-100 mx-auto p-0  h-100 " } >
      <CCol  sm="8" md="6" xs="12" className={"my-auto  d-none d-md-flex justify-content-center"}>
        <div className="d-inline-block pl-sm-5 px-2 pt-sm-0 pt-4 ">
          <p className="Nunito h1  text-center  text-sm-left" style={{color:"black"}}>Welcome to</p>
          <div className="text-center  text-sm-left">
            <p className="Goldman text-primary name d-inline-block">
              Mobigic
            </p>
            <h1 className="mb-auto text-primary d-inline-block">&reg;</h1>
          </div>
          <p className="Nunito h1 text-center  text-sm-left " style={{color:"black"}}>Technologies Private Limited.</p>
          <p className="Nunito h4 text-warning  text-center  text-sm-left " style={{fontWeight:"bold"}}>"Where Business Meets Technology."</p>
        </div>
      </CCol>
      <CCol  sm="8" md="6" xs="12" className={"my-auto d-flex  d-md-none justify-content-center"}>
        <div className="d-inline-block pl-sm-5 px-2 pt-sm-0 pt-4 ">
          <p className="Nunito h1  text-center  text-sm-left" style={{color:"black"}}>Welcome to</p>
          <div className="text-center  text-sm-left">
            <p className="Goldman text-primary name d-inline-block">
              Mobigic
            </p>
            <h1 className="mb-auto text-primary d-inline-block">&reg;</h1>
          </div>
          <p className="Nunito h1 text-center  text-sm-left " style={{color:"black"}}>Technologies Private Limited.</p>
          <p className="Nunito h4 text-warning text-center  text-sm-left " style={{fontWeight:"bold"}}>"Where Business Meets Technology."</p>
        </div>
      </CCol>
      <CCol sm="4" md="6" className={"h-100 d-none  d-md-flex justify-content-end p-0"}>
        <img
          src={banner}
          alt=""
          className="h-100"
          style={{ objectFit: "contain" }}
        />
      </CCol>
    </CRow>
  );
}
