
import axios from '../../../axios'
let createNotification = async (body)=>{
        return axios.post(`${process.env.REACT_APP_API_BASE_URL}/notification`,body,{headers: { authorization: localStorage.getItem("token") }})
}
let getNotification = async (query)=>{

        return axios.get(`http://localhost:1337/api/notification` + query && query,{headers: { authorization: localStorage.getItem("token") }})
}

export default {createNotification,getNotification}
