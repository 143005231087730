import { CCol, CRow } from "@coreui/react";
import "./style.css";
import { BsFacebook, BsLinkedin, BsTwitter, BsWhatsapp } from "react-icons/bs";
export default function Contact() {
  return (
    <CRow className={"pb-4 mt-4 mx-auto"}>
      <CCol xs="12" className={"text-center py-4"}>
        <p className="h3 Goldman">Contact Us</p>
      </CCol>
      <CCol
        xs="10"
        sm="4"
        md="3"
        className={
          "d-flex order-1 order-sm-1 order-md-1 mx-auto  justify-content-sm-center"
        }
      >
        <div className="  d-inline-block">
          <p className="mb-1">
            <a className="text-primary">hr@mobigic.com</a>
          </p>
          <p className="mb-1">
            {" "}
            <a className="text-primary">info@mobigic.com</a>
          </p>
          <p className="mb-">
            <a href="https://www.mobigic.com" target="_blank">
              www.mobigic.com
            </a>
          </p>
        </div>
      </CCol>
      <CCol
        xs="10"
        sm="8"
        md="3"
        className={"order-2 order-sm-2 order-md-2 mx-auto"}
      >
        <p>
          <p className="font-weight-bold mb-0">Pune Office: </p>
          <small>
            Office No. 5, 3'rd floor,Urban Bliss, Shivneri Colony,Opp Krushna
            Medical,PAN Card Club Rd, Baner, Pune, Maharashtra 411045
          </small>
        </p>
      </CCol>
      <CCol
        xs="10"
        sm="8"
        md="3"
        className={"order-3 order-sm-4 order-md-3 mx-auto"}
      >
        <p>
          <p className="font-weight-bold mb-0">Thane Office:</p>
          <small>
            Tower B, Lodha Supremus,1104, 11th Floor, Kolshet Rd,near Air Force
            Station,Thane, Maharashtra 400607
          </small>
        </p>
      </CCol>
      <CCol xs="12" sm="4" md="3" className={" order-4 order-sm-3 order-md-4"}>
        <CCol xs="10" className={"d-flex mx-auto justify-content-around"}>
          <a
            href="https://www.linkedin.com/in/mobigic-technologies-private-limited-25a5151a0/"
            target="_blank"
          >
            <BsLinkedin size={"24"}></BsLinkedin>
          </a>
          <a href="https://www.facebook.com/mobigic" target="_blank">
            <BsFacebook size={"24"}></BsFacebook>
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=%2B917977040526&data_filter_required=ARDhC3rlfR4u3_NvyBIS05VNezWF89HeKIo6w1aAeXiAFRNuBM3xef1KfaaWUtFMovidQ1k0z4v_4MI-Y5c7lKsdLg-4rOF0ECmCya1CW00rMIzaALd-enbQBQFhyPIC9Ux-G5qL32T3dY0lDIypzT_1uQ&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwAR2EPKMolBB-PLwzsNn4bJpVNtddK5YtJgPWWAiXg0dMiEYwOcNiMEnSRX0"
            target="_blank"
          >
            <BsWhatsapp size={"24"}></BsWhatsapp>
          </a>
          <a href="https://twitter.com/mobigic_india" target="_blank">
            <BsTwitter size={"24"}></BsTwitter>
          </a>
        </CCol>
      </CCol>
      <CCol xs="12" className={"text-center mt-2 order-5"}>
        <small className="Nunito">
          Copyrights © 2023 All Rights Reserved by Mobigic® Technologies Pvt.
          Ltd.
        </small>
      </CCol>
    </CRow>
  );
}
