import React, { useEffect, useState } from "react";
import axios from '../axios'
import {
  MdStreetview,
  MdOutlineVideoCameraFront,
  MdTask,
  MdSpaceDashboard,
  MdCircleNotifications,
} from "react-icons/md";
import {
  BsFillPeopleFill,
  BsPersonVideo,
  BsFillPersonLinesFill,
  BsFillQuestionCircleFill,
} from "react-icons/bs";
import { VscTasklist } from "react-icons/vsc";
import { IoMdCall } from "react-icons/io";
import { FaUserCircle, FaAddressBook } from "react-icons/fa";
import { BsPersonFillAdd } from "react-icons/bs";
import { HiUserAdd } from "react-icons/hi";
import { RiFileSettingsFill } from "react-icons/ri";
import { HiDocumentPlus, HiDocumentText, HiDocument } from "react-icons/hi2";
import { IoNotificationsCircle  } from "react-icons/io5";
import { Badge } from "react-bootstrap";
import NotificationSwitch from "./NotificationSwitch";

const hr = "hr";
const admin = "admin";
const evaluator = "evaluator";
const role = localStorage.getItem("role");
let _nav = [];
if (role === evaluator) {
  _nav = [
    {
      _tag: "CSidebarNavItem",
      name: "Dashboard",
      to: "/home/dashboard",
      icon: <MdSpaceDashboard className="mr-2" size={20}></MdSpaceDashboard>,
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "Candidates",
      icon: <BsFillPeopleFill className="mr-1" size={20}></BsFillPeopleFill>,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "View Candidates",
          to: "/home/pages/profiles",
          icon: <MdStreetview className="mr-2" size={18}></MdStreetview>,
          color: "light",
        },
        // {
        //   _tag: "CSidebarNavItem",
        //   name: "Add Candidates",
        //   to: "/pages/AddCandidates",
        //   // icon: 'cil-pencil',
        // },
      ],
    },

    //

    
    {
      _tag: "CSidebarNavDropdown",
      name: " Job Description",
      icon: <HiDocument className="mr-1" size={20}></HiDocument>,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "View JDs",
          to: "/home/pages/jds",
          icon: <HiDocumentText className="mr-2" size={18}></HiDocumentText>,
          color: "light",
        },
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: " Job Applications",
      icon: <HiDocument className="mr-1" size={20}></HiDocument>,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "View Applications",
          to: "/home/pages/applications",
          icon: <HiDocumentText className="mr-2" size={18}></HiDocumentText>,
          color: "light",
        },

      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "Evaluations",
      icon: <VscTasklist className="mr-1" size={20}></VscTasklist>,
      _children: [
        // {
        //   _tag: "CSidebarNavItem",
        //   name: "Telephonic Call",
        //   to: "/pages/telephonic",
        //   // icon: 'cil-pencil',
        // },
        {
          _tag: "CSidebarNavItem",
          name: "Online Interview",
          to: "/home/pages/onlineInterview",
          // icon: 'cil-pencil',
          icon: <BsPersonVideo className="mr-2" size={18}></BsPersonVideo>,
          color: "light",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Face To Face Interview",
          to: "/home/pages/face-to-face-interview",
          // icon: 'cil-pencil',
          icon: (
            <MdOutlineVideoCameraFront
              className="mr-2"
              size={18}
            ></MdOutlineVideoCameraFront>
          ),
          color: "light",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Online Test",
          to: "/home/pages/online-test",
          icon: <MdTask className="mr-2" size={18}></MdTask>,
          color: "light",
        },
      ],
    },
    {
      _tag: "CSidebarNavItem",
      name: <NotificationSwitch></NotificationSwitch> ,
      to: "/home/pages/notification",
      // icon: 'cil-pencil',
      icon: <MdCircleNotifications  className="mr-2" size={23}></MdCircleNotifications>,
    },
  ];
}
if (role === hr) {
  _nav = [
    {
      _tag: "CSidebarNavItem",
      name: "Dashboard",
      to: "/home/dashboard",
      icon: <MdSpaceDashboard className="mr-2" size={20}></MdSpaceDashboard>,
    },

    //
    {
      _tag: "CSidebarNavDropdown",
      name: "Candidates",
      icon: <BsFillPeopleFill className="mr-1" size={20}></BsFillPeopleFill>,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "View Candidates",
          to: "/home/pages/profiles",
          icon: <MdStreetview className="mr-2" size={18}></MdStreetview>,
          color: "light",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Add Candidates",
          to: "/home/pages/AddCandidates",
          icon: <BsPersonFillAdd className="mr-2" size={18}></BsPersonFillAdd>,
          color: "light",
        },
      ],
    },

    //

    {
      _tag: "CSidebarNavDropdown",
      name: " Job Description",
      icon: <HiDocument className="mr-1" size={20}></HiDocument>,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "View JDs",
          to: "/home/pages/jds",
          icon: <HiDocumentText className="mr-2" size={18}></HiDocumentText>,
          color: "light",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Create JD",
          to: "/home/pages/addjd",
          icon: <HiDocumentPlus className="mr-2" size={18}></HiDocumentPlus>,
          color: "light",
        },
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: " Job Applications",
      icon: <HiDocument className="mr-1" size={20}></HiDocument>,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "View Applications",
          to: "/home/pages/applications",
          icon: <HiDocumentText className="mr-2" size={18}></HiDocumentText>,
          color: "light",
        },

      ],
    },
    {
      _tag: "CSidebarNavItem",
      name: "Question Bank",
      to: "/home/pages/questions",
      icon: <BsFillQuestionCircleFill className="mr-2" size={20}></BsFillQuestionCircleFill>,
      // color: "light",
    },
    // {
    //   _tag: "CSidebarNavDropdown",
    //   name: " Job Applications",
    //   icon: <HiDocument className="mr-1" size={20}></HiDocument>,
    //   _children: [
    //     {
    //       _tag: "CSidebarNavItem",
    //       name: "View Applicatios",
    //       to: "/home/pages/applications",
    //       icon: <HiDocumentText className="mr-2" size={18}></HiDocumentText>,
    //       color: "light",
    //     },
       
    //   ],
    // },
    {
      _tag: "CSidebarNavDropdown",
      name: "Evaluations",
      icon: <VscTasklist className="mr-1" size={20}></VscTasklist>,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "Telephonic Call",
          to: "/home/pages/telephonic",
          icon: <IoMdCall className="mr-2" size={18}></IoMdCall>,
          color: "light",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Online Interview",
          to: "/home/pages/onlineInterview",
          icon: (
            <MdOutlineVideoCameraFront
              className="mr-2"
              size={18}
            ></MdOutlineVideoCameraFront>
          ),
          color: "light",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Face To Face Interview",
          to: "/home/pages/face-to-face-interview",
          icon: (
            <BsFillPersonLinesFill
              className="mr-2"
              size={18}
            ></BsFillPersonLinesFill>
          ),
          color: "light",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Online Test",
          to: "/home/pages/online-test",
          icon: <MdTask className="mr-2" size={18}></MdTask>,
          color: "light",
        },
      ],
    },
    {
      _tag: "CSidebarNavItem",
      name: <NotificationSwitch></NotificationSwitch> ,
      to: "/home/pages/notification",
      // icon: 'cil-pencil',
      icon: <MdCircleNotifications  className="mr-2" size={23}></MdCircleNotifications>,
    },
    {
      _tag: "CSidebarNavItem",
      name: "Follow Up",
      to: "/home/pages/tasks",
      // icon: 'cil-pencil',
      icon: <FaAddressBook className="mr-2" size={18}></FaAddressBook>,
    },
    
  ];
}
if (role === admin) {
  _nav = [
    {
      _tag: "CSidebarNavItem",
      name: "Dashboard",
      to: "/home/dashboard",
      icon: <MdSpaceDashboard className="mr-2" size={20}></MdSpaceDashboard>,
    },

    {
      _tag: "CSidebarNavDropdown",
      name: "Candidates",
      icon: <BsFillPeopleFill className="mr-1" size={20}></BsFillPeopleFill>,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "View Candidates",
          to: "/home/pages/profiles",
          color: "light",
          icon: <MdStreetview className="mr-2" size={18}></MdStreetview>,
        },
        {
          _tag: "CSidebarNavItem",
          name: "Add Candidates",
          to: "/home/pages/AddCandidates",
          color: "light",
          icon: <BsPersonFillAdd className="mr-2" size={18}></BsPersonFillAdd>,
        },
      ],
    },

    //

    {
      _tag: "CSidebarNavDropdown",
      name: " Job Description",
      icon: <HiDocument className="mr-1" size={20}></HiDocument>,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "View JDs",
          to: "/home/pages/jds",
          icon: <HiDocumentText className="mr-2" size={18}></HiDocumentText>,
          color: "light",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Create JD",
          to: "/home/pages/addjd",
          icon: <HiDocumentPlus className="mr-2" size={18}></HiDocumentPlus>,
          color: "light",
        },
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: " Job Applications",
      icon: <HiDocument className="mr-1" size={20}></HiDocument>,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "View Applications",
          to: "/home/pages/applications",
          icon: <HiDocumentText className="mr-2" size={18}></HiDocumentText>,
          color: "light",
        },
        
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "Evaluations",
      icon: <VscTasklist className="mr-1" size={20}></VscTasklist>,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "Telephonic Call",
          to: "/home/pages/telephonic",
          icon: <IoMdCall className="mr-2" size={18}></IoMdCall>,
          color: "light",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Online Interview",
          to: "/home/pages/onlineInterview",
          icon: (
            <MdOutlineVideoCameraFront
              className="mr-2"
              size={18}
            ></MdOutlineVideoCameraFront>
          ),
          color: "light",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Face To Face Interview",
          to: "/home/pages/face-to-face-interview",
          icon: (
            <BsFillPersonLinesFill
              className="mr-2"
              size={18}
            ></BsFillPersonLinesFill>
          ),
          color: "light",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Online Test",
          to: "/home/pages/online-test",
          icon: <MdTask className="mr-2" size={18}></MdTask>,
          color: "light",
        },
      ],
    },
    {
      _tag: "CSidebarNavItem",
      name: "Question Bank",
      to: "/home/pages/questions",
      icon: <BsFillQuestionCircleFill className="mr-2" size={20}></BsFillQuestionCircleFill>,
      // color: "light",
    },
    // {
    //   _tag: 'CSidebarNavDropdown',
    //   name: 'Evaluations',
    //   _children: [

    //     {
    //       _tag: 'CSidebarNavItem',
    //       name: 'Pending Evaluations',
    //       to: '/pages/evl',
    //       // icon: 'cil-pencil',
    //     },
    //   ],
    // },
    // {
    //   _tag: 'CSidebarNavDropdown',
    //   name: 'Offers',
    //   _children: [

    //     {
    //       _tag: 'CSidebarNavItem',
    //       name: 'Offers',
    //       to: '/pages/offers',
    //       // icon: 'cil-pencil',
    //     },
    //   ],
    // },
    {
      _tag: "CSidebarNavDropdown",
      name: "Users",
      icon: <FaUserCircle className="mr-1" size={20}></FaUserCircle>,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "Users",
          to: "/home/users",
          // icon: 'cil-pencil',
          icon: <FaAddressBook className="mr-2" size={18}></FaAddressBook>,
          color: "light",
        },

        {
          _tag: "CSidebarNavItem",
          name: "Add User",
          to: "/home/adduser",
          // icon: 'cil-pencil',
          icon: <HiUserAdd className="mr-2" size={18}></HiUserAdd>,
          color: "light",
        },
      ],
    },
    {
      _tag: "CSidebarNavItem",
      name: <NotificationSwitch></NotificationSwitch> ,
      to: "/home/pages/notification",
      // icon: 'cil-pencil',
      icon: <MdCircleNotifications  className="mr-2" size={23}></MdCircleNotifications>,
    },
    {
      _tag: "CSidebarNavItem",
      name: "Follow Up",
      to: "/home/pages/tasks",
      // icon: 'cil-pencil',
      icon: <FaAddressBook className="mr-2" size={18}></FaAddressBook>,
    },
    {
      _tag: "CSidebarNavItem",
      name: "Manage",
      to: "/home/pages/manage",
      // icon: 'cil-pencil',
      icon: <RiFileSettingsFill className="mr-2" size={18}></RiFileSettingsFill>,
    },
  ];
}

export default _nav;


// function NotificationSwitch(){
//   let [notification, setNotification] = useState([])
//   useEffect(()=>{
//     axios.get(`${process.env.REACT_APP_API_BASE_URL}/notification`,{headers: { authorization: localStorage.getItem("token") }}).then((res)=>{
//       setNotification(res.data);
//     })
//   },[])
//   return <>Notification {notification.length > 0 &&<Badge variant="danger">{notification.length}</Badge>}</>
// }