import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import "react-dropdown/style.css";

import {
  CHeader,
  CToggler,
  CSubheader,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
  CDropdownToggle,
  CButton,
} from "@coreui/react";

// routes config
import "./style.css";
import { UserContext, sideBarContext } from "src/UserContext";
import { useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { IconContext } from "react-icons";
import Notification from "src/views/dashboard/Notification";

const TheHeader = () => {
  let history = useHistory();
  // let { user } = useContext(UserContext);
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const [location, setLocation] = useState(localStorage.getItem("location"));
  useEffect(() => {
    setLocation(localStorage.getItem("location"));
    // localStorage.setItem('location','Candidates/View Candidates')
  }, [localStorage]);
  // const toggleSidebar = () => {
  //   const val = [true, "responsive"].includes(sidebarShow)
  //     ? false
  //     : "responsive";
  //   dispatch({ type: "set", sidebarShow: val });
  // };
  const [user, setUser] = useState("");
  const [show, setShow] = useState(true);
  const { path, setPath } = useContext(UserContext);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/findUser`, {
        headers: {
          authorization: localStorage.getItem("token"),
          id: localStorage.getItem("id"),
        },
      })
      .then((res) => {
        if (res) {
          if (res.data.token) {
            localStorage.setItem("token", "token");
            window.location.reload();
          } else {
            setUser(res.data);
          }
        }
      });
  }, []);

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch({ type: "set", sidebarShow: val });
  };
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("id");
    localStorage.removeItem("refreshToken");
    history.replace("/home/dashboard");
    // history.push({
    //   pathname: "/",
    // });
    window.location.reload();
  };
  const dropdownRef = useRef();
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleDropdownToggle = () => {
    dropdownRef.current.classList.toggle("show");
  };

  const handleOutsideClick = (event) => {
    if (!dropdownRef.current || dropdownRef.current.contains(event.target)) {
      return;
    }

    dropdownRef.current.classList.remove("show");
  };
  let { sideBar, setSideBar } = useContext(sideBarContext);
  return (
    <CHeader withSubheader>
      <CSubheader className=" d-flex flex-row">
        {/* <div
          style={{ width: "85%", display: "flex", justifyContent: "end" }}
          className="d-md-down-none mfe-2  c-subheader-nav bg-dark"
        > */}

        <div
          style={{
            //  width: "auto",
            display: "flex",
            justifyContent: "start",
          }}
          className=" col-6 col-sm-8 col-md-9"
        >
          <CToggler
            inHeader
            // d-lg-none
            className="ml-md-3 "
            onClick={() => {
              if (sideBar) {
                setSideBar(false);
              } else {
                setSideBar(true);
              }
            }}
          />
          <div
            style={{ height: "100%", alignItems: "center" }}
            className="pl-1 text-primary d-md-flex  d-none"
          >
            <div className="ml-1">{path}</div>
          </div>
          
        </div>
        {/* </div> */}
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            justifyContent:"end"
            // width: "15%",
            
          }}
          className="col-md-3 pl-0 col-sm-4 col-5"
          id="dropdown-basic"
          
        >{localStorage.getItem("role") === "admin" && (
          <div className="ml-auto d-flex justify-content-between align-items-center">
            <CDropdown className="  ">
              <CDropdownToggle
                className={
                  "d-flex justify-content-between align-items-center dropdown-toggle text-white font-weight-bold p-2 rounded-circle"
                }
                href="#"
                color="primary"
              >
                {/* <IconContext.Provider value={{ color: "white" }}> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-plus-lg"
                  viewBox="0 0 16 16"
                  style={{ color: "white" }}
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                  />
                </svg>
                {/* </IconContext.Provider> */}
              </CDropdownToggle>

              <CDropdownMenu>
                <CDropdownItem
                  onClick={() => {
                    history.push({
                      pathname: "/home/pages/AddCandidates",
                    });
                  }}
                  href="#"
                >
                  New Candidate
                </CDropdownItem>
                <CDropdownItem
                  onClick={() => {
                    history.push({
                      pathname: "/home/pages/addjd",
                    });
                  }}
                  href="#"
                >
                  New Job Description
                </CDropdownItem>
                <CDropdownItem
                  onClick={() => {
                    history.push({
                      pathname: "/home/adduser",
                    });
                  }}
                  href="#"
                >
                  New User
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </div>
        )}
          <Dropdown className="p-0 ml-0 mr-1 my-auto">
            <Dropdown.Toggle
              className="p-0 btn"
              variant="success"
              id="dropdown-basic"
              style={{
                border: "none",
                backgroundColor: "white",
                color: "black",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div className=" d-flex align-items-center ml-3 my-auto mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  class="bi bi-person-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path
                    fill-rule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                  />
                </svg>
              </div>
              <div className="d-flex flex-column  align-items-start">
                <small>{user.name}</small>
                <small className="text-primary">
                  {user.role == "admin"
                    ? "Admin"
                    : user.role == "evaluator"
                    ? "Evaluator"
                    : user.role == "hr"
                    ? "Hr"
                    : ""}
                </small>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="p-0">
              <Dropdown.Item
                onClick={() => {
                  localStorage.removeItem("location");
                  logout();
                }}
              >
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div></div>
        </div>
      </CSubheader>
    </CHeader>
  );
};

export default TheHeader;
