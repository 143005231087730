import { CCol, CRow } from "@coreui/react";
// import ReactDOM from 'react-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img1 from "./gallery/img1.png";
import img2 from "./gallery/img2.png";
import img3 from "./gallery/img3.png";
import img4 from "./gallery/img4.png";
import img5 from "./gallery/img5.png";
import img6 from "./gallery/img6.png";
import img7 from "./gallery/img7.png";
import img8 from "./gallery/img8.png";
import img9 from "./gallery/img9.png";
import img10 from "./gallery/img10.png";
import img11 from "./gallery/img11.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
let style={
    height:"15em"
}
let imgStyle={objectFit:"cover",border:"6px solid white",borderRadius:"6px"}
export default function Gallery(props) {
  return (
    <CRow className={"bg-primary mx-auto"}>
        <CCol xs="12" className={"mt-4 mb-3 text-center"}>
        <p className="h3 Goldman">Mobigic Gallery</p>
      </CCol>
      <CCol className={"col-12 col-md-10 mx-auto mt-2 mb-5"}>
        <Carousel
          className="w-100"
          swipeable={true}
          draggable={false}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={props.deviceType !== "mobile" ? true : false}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={1000}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          deviceType={props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className="d-flex justify-content-center align-items-center mx-1" style={style}>
            <img className="h-100 w-100 h-sm-75 w-sm-75" style={imgStyle} src={img1} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          <div className="d-flex justify-content-center align-items-center mx-1" style={style}>
            <img className="h-100 w-100 h-sm-75 w-sm-75" style={imgStyle} src={img2} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          <div className="d-flex justify-content-center align-items-center mx-1" style={style}>
            <img className="h-100 w-100 h-sm-75 w-sm-75" style={imgStyle} src={img3} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          <div className="d-flex justify-content-center align-items-center mx-1" style={style}>
            <img className="h-100 w-100 h-sm-75 w-sm-75" style={imgStyle} src={img4} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          <div className="d-flex justify-content-center align-items-center mx-1" style={style}>
            <img className="h-100 w-100 h-sm-75 w-sm-75" style={imgStyle} src={img5} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          <div className="d-flex justify-content-center align-items-center mx-1" style={style}>
            <img className="h-100 w-100 h-sm-75 w-sm-75" style={imgStyle} src={img6} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          <div className="d-flex justify-content-center align-items-center mx-1" style={style}>
            <img className="h-100 w-100 h-sm-75 w-sm-75" style={imgStyle} src={img7} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          <div className="d-flex justify-content-center align-items-center mx-1" style={style}>
            <img className="h-100 w-100 h-sm-75 w-sm-75" style={imgStyle}src={img8} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          <div className="d-flex justify-content-center align-items-center mx-1" style={style}>
            <img className="h-100 w-100 h-sm-75 w-sm-75" style={imgStyle} src={img9} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          <div className="d-flex justify-content-center align-items-center mx-1" style={style}>
            <img className="h-100 w-100 h-sm-75 w-sm-75" style={imgStyle} src={img10} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          <div className="d-flex justify-content-center align-items-center mx-1" style={style}>
            <img className="h-100 w-100 h-sm-75 w-sm-75" style={imgStyle} src={img11} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          
        </Carousel>
      </CCol>
    </CRow>
  );
}
