import {
  CCard,
  CCardBody,
  CCol,
  CFormGroup,
  CLabel,
  CDataTable,
  CButton,
  CInput,
  CCardHeader,
  CRow,
} from "@coreui/react";
import {
  MdEditSquare,
  MdOutlineVideoCameraFront,
  MdTask,
} from "react-icons/md";
import "./scroll.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { ImCheckmark } from "react-icons/im";
import { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import axios from "../../../../axios";
import swal from "sweetalert";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { IoIosSend, IoMdCall } from "react-icons/io";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { changeCurrentStatus } from "src/redux-toolkit/CandidateSlice";
import { useDispatch } from "react-redux";
import TestPortal from "./TestPortal";
import ViewStatus from "./ViewTest";
let Telelevel = [
  { level: 1, value: "pending" },
  { level: 2, value: "completed" },
];
let testlevel = [
  { level: 1, value: "pending" },
  { level: 2, value: "not attempted" },
  { level: 3, value: "attempted" },
  { level: 4, value: "passed" },
  { level: 5, value: "failed" },
];
let facelevel = [
  { level: 1, value: "pending" },
  { level: 2, value: "not attempted" },
  { level: 3, value: "passed" },
  { level: 4, value: "failed" },
];
let onlinelevel = [
  { level: 1, value: "pending" },
  { level: 2, value: "not attempted" },
  { level: 3, value: "passed" },
  { level: 4, value: "failed" },
];
const CreateEvaluationList = (props) => {
  let dispatch = useDispatch();
  const EvalFields = [
    "evaluation_type",
    "action_date",
    "time",
    "evaluator",
    "Test_link",
    "Test",
    "evaluation_status",
    // "remark",
  ];
  const evaluatorField = [
    "evaluation_type",
    "action_date",
    "time",
    "Test",
    "Test_link",
    "evaluation_status",
    // "remark",
  ];
  const [sel, setSelect] = useState(true);
  const [evaluators, setEval] = useState([]);
  const [type, setType] = useState("");
  const [can_id, setCan_id] = useState("");
  const [evaluator, setEvaluator] = useState("");
  const [typeSelect, setTypeSelect] = useState(true);
  const [time, setTime] = useState("");
  // const [documents, setDocs] = useState([]);
  //   const [date,setDate]=useState()
  const [closeDate, setClosedDateEval] = useState();
  const [evaluations, setEvaluations] = useState([]);
  const [changeEvaluatorList, setChangeEvaluatorList] = useState(false);
  const [telephonicEvaluator, setTelephonicEvaluator] = useState([]);
  const [request, setRequest] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [teleStatusValues, setTeleStatusValues] = useState([
    "pending",
    "completed",
  ]);
  const [faceStatusValues, setFaceStatusValues] = useState([
    "pending",
    "not attempted",
    "passed",
    "failed",
  ]);

  const [OnlineStatusValues, setOnlineStatusValues] = useState([
    "pending",
    "not attempted",
    "passed",
    "failed",
  ]);
  const [onlineTestStatusValues, setOnlineTestStatusValues] = useState([
    "pending",
    "attempted",
    "not attempted",
    "passed",
    "failed",
  ]);
  const [onlineTestStatusValuesEval, setOnlineTestStatusValuesEval] = useState([
    "pending",
    "not attempted",
    "attempted",
    "passed",
    "failed",
  ]);
  const [application, setApplication] = useState();
  useEffect(() => {
    setEval(props.data.evaluators);
    setTelephonicEvaluator(props.data.telephonic);
  }, [props.data]);
  var MaxDate = new Date();
  MaxDate = MaxDate.setMonth(MaxDate.getMonth() + 3);
  function createEvaluation() {
    if (closeDate) {
      let timeApi = new Date().getTime();
      setRequest(true);
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/addEvaluation?time=${timeApi}`,
          {
            type,
            closeDate: closeDate.getTime(),
            evaluator,
            can_id,
            jd_id: props.data.jd_id,
            time,
          },
          {
            headers: { authorization: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          dispatch(changeCurrentStatus("Inprocess"));
          if (evaluations) {
            setEvaluations((obj) => {
              return [res.data, ...obj];
            });
            props.data.setEvaluations((obj) => {
              return [res.data, ...obj];
            });
          } else {
            setEvaluations([res.data]);
            // setEvaluations([res.data, ...props.data.evaluations]);
            props.data.setEvaluations((obj) => {
              return [res.data];
            });
          }

          swal({
            title: "Success!",
            text: "New Evaluation Added",
            timer: 1500,
            icon: "success",
            button: false,
          });

          setType("");
          setEvaluator("");
          setTime("");
          setClosedDateEval();
          setSelect(true);
          setTypeSelect(true);
          setRequest(false);
        });
    }
  }
  useEffect(() => {
    setCan_id(props.data.can_id);
    setEvaluations(props.data.evaluations);
  }, [props.data]);
  useEffect(() => {
    setApplication(props.data.application ? props.data.application : null);
  }, [props.data]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/getQuestions`, {
        headers: { authorization: localStorage.getItem("token") },
      })
      .then((response) => {
        if (response) {
          if (response.data.token) {
            localStorage.setItem("token", "token");
            localStorage.removeItem("refreshToken");
            window.location.reload();
          } else {
            setQuestions(response.data);
          }
        }
      });
  }, []);

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_API_BASE_URL}/getEvaluation`, {
  //       headers: {
  //         authorization: localStorage.getItem("token"),
  //         can_id: props.data.can_id,
  //         jd_id: props.data.jd_id,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.token) {
  //         localStorage.removeItem("token");
  //         window.location.reload();
  //       } else {
  //         let array = res.data.map((obj) => {
  //           return { id: obj.id, link: obj.link };
  //         });
  //         setLink(array);
  //         setEvaluations(res.data);
  //       }
  //     });
  // }, []);
  // useEffect(() => {
  //   setCan_id(props.data.can_id);
  //   axios
  //     .get(`${process.env.REACT_APP_API_BASE_URL}/getallEvalutors`, {
  //       headers: {
  //         authorization: localStorage.getItem("token"),
  //       },
  //     })

  //     .then((res) => {
  //       if (res.data.token) {
  //         localStorage.removeItem("token");
  //         window.location.reload();
  //       } else {
  //         let ar = res.data.map((tg) => {
  //           return {
  //             value: tg.name,
  //             label: tg.name,
  //             id: tg.id,
  //           };
  //         });

  //         setEval(ar);
  //       }
  //     });
  //   // axios
  //   //   .get(`${process.env.REACT_APP_API_BASE_URL}/getEvaluation`, {
  //   //     headers: {
  //   //       authorization: localStorage.getItem("token"),
  //   //       can_id: props.data.can_id,
  //   //       jd_id: props.data.jd_id,
  //   //     },
  //   //   })
  //   //   .then((res) => {
  //   //     if (res.data.token) {
  //   //       localStorage.removeItem("token");
  //   //       window.location.reload();
  //   //     } else {
  //   //       let array = res.data.map((obj) => {
  //   //         return { id: obj.id, link: obj.link };
  //   //       });
  //   //       setLink(array);
  //   //       setEvaluations(res.data);
  //   //     }
  //   //   });
  //   axios
  //     .get(`${process.env.REACT_APP_API_BASE_URL}/getAllUsers`, {
  //       headers: { authorization: localStorage.getItem("token") },
  //     })
  //     .then((res) => {
  //       if (res) {
  //         if (res.data.token) {
  //           localStorage.setItem("token", "token");
  //           window.location.reload();
  //         } else {
  //           setTelephonicEvaluator(res.data);
  //         }
  //       }
  //       //change here /getallEvalutors later
  //     });
  // }, []);
  function changeStatus(status, id) {
    axios
      .patch(
        `${process.env.REACT_APP_API_BASE_URL}/changeEvaluationStatus`,
        { status, id },
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      )
      .then((response) => {
        if (response) {
          if (response.data.token) {
            localStorage.setItem("token", "token");
            localStorage.removeItem("refreshToken");
            window.location.reload();
          } else {
            props.data.setEvaluations((obj) => {
              let array = obj.map((Evalstatus) => {
                if (Evalstatus.id == id) {
                  Evalstatus.evaluation_status = status;
                }
                return Evalstatus;
              });
              return array;
            });
            setEvaluations((array) => {
              return array.filter((obj) => {
                if (obj.id == id) {
                  obj.evaluation_status = status;
                  return obj;
                } else {
                  return obj;
                }
              });
            });
            swal({
              title: "Success!",
              text: "Status Changed",
              timer: 1500,
              icon: "success",
              button: false,
            });
          }
        }
      });
  }
  return (
    <CCard className={"  bg-light"}>
      {/* <CCardHeader>JDID</CCardHeader> */}
      {application &&
      application.application_status != "rejected" &&
      application.application_status != "done" &&
      application.application_status != "drop" ? (
        <CCardHeader className={"font-weight-bold"}>
          {localStorage.getItem("role") === "evaluator"
            ? "Evaluations"
            : "Create Evaluation"}
        </CCardHeader>
      ) : (
        <CCardHeader className={"font-weight-bold"}>Evaluations</CCardHeader>
      )}
      {localStorage.getItem("role") !== "evaluator" &&
        application &&
        application.application_status != "rejected" &&
        application.application_status != "done" &&
        application.application_status != "drop" && (
          <CCardBody xs="12" className={"row "}>
            <CCol xs="6" sm="4" md="3">
              <CFormGroup>
                <CLabel htmlFor="name" className={"my-0"}>
                  Type
                </CLabel>
                <select
                  onChange={(e) => {
                    setTypeSelect(false);
                    setType(e.target.value);
                    setEvaluator("");
                    setSelect(true);
                    if (e.target.value === "Telephonic") {
                      setChangeEvaluatorList(true);
                    } else {
                      setChangeEvaluatorList(false);
                    }
                  }}
                  class="form-control form-control-md w-100"
                >
                  {typeSelect && (
                    <option selected={typeSelect}>Select Type</option>
                  )}
                  <option value={"Telephonic"} className="text-dark">
                    Telephonic
                  </option>
                  <option value={"Online Test"} className="text-dark">
                    Online Test
                  </option>
                  <option value={"Online Interview"} className="text-dark">
                    Online Interview
                  </option>
                  <option value={"Face To Face"} className="text-dark">
                    Face To Face Interview
                  </option>
                </select>
              </CFormGroup>
            </CCol>
            <CCol xs="6" sm="4" md="3">
              <CFormGroup>
                <CLabel htmlFor="name" className={"my-0"}>
                  Evaluators
                </CLabel>
                <select
                  onChange={(e) => {
                    setEvaluator(e.target.value);

                    setSelect(false);
                  }}
                  class="form-control form-control-md w-100"
                >
                  {sel && (
                    <option value={""} selected={sel}>
                      Select Evaluator
                    </option>
                  )}
                  {!changeEvaluatorList &&
                    evaluators.map((obj) => {
                      return (
                        <option value={obj.id} className="text-dark">
                          {obj.value}
                        </option>
                      );
                    })}
                  {changeEvaluatorList &&
                    telephonicEvaluator.map((obj) => {
                      if (obj.role === "hr") {
                        return (
                          <option value={obj.id} className="text-dark">
                            {obj.name}
                          </option>
                        );
                      } else {
                        return null;
                      }
                    })}
                </select>
              </CFormGroup>
            </CCol>
            <CCol xs="4" sm="4" md="2">
              <CFormGroup>
                <CLabel htmlFor="name" className={"my-0"}>
                  Date
                </CLabel>
                {/* <CCol
                xs="12"
                sm="12"
                className={"py-1 bg-white d-flex"}
                style={{
                  border: "1px solid #cfc9c8",
                  borderRadius: 4,
                }}
              > */}
                <ReactDatePicker
                  className="form-control w-100"
                  // className="picker w-100"
                  dateFormat="dd-MMM-yyyy"
                  selected={closeDate}
                  maxDate={MaxDate}
                  minDate={new Date()}
                  placeholderText="Select Date"
                  onChange={(date) => {
                    setClosedDateEval(date);
                    setTime("");
                  }}
                />
                {/* </CCol> */}
              </CFormGroup>
            </CCol>

            <CCol xs="4" sm="4" md="2">
              <CFormGroup>
                <CLabel htmlFor="name" className={"my-0"}>
                  Time
                </CLabel>
                {/* <CCol
                xs="12"
                sm="12"
                className={"py-1 bg-white d-flex"}
                style={{
                  border: "1px solid #cfc9c8",
                  borderRadius: 4,
                }}
              > */}
                <ReactDatePicker
                  placeholderText="Select Time"
                  selected={time}
                  onChange={(time) => {
                    // if (time != "Invalid Date") {
                    setTime(time);
                    // }
                  }}
                  // className="picker w-100"
                  className="form-control w-100"
                  showTimeSelect
                  minTime={
                    moment(closeDate).format("DD/MM/YY") ==
                    moment(new Date()).format("DD/MM/YY")
                      ? setHours(
                          setMinutes(new Date(), new Date().getMinutes()),
                          new Date().getHours()
                        )
                      : setHours(setMinutes(new Date(), 30), 8)
                  }
                  maxTime={setHours(setMinutes(new Date(), 30), 20)}
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
                {/* </CCol> */}
              </CFormGroup>
            </CCol>

            <CCol
              xs="4"
              sm="4"
              md="2"
              className={" d-flex align-items-center pt-1"}
            >
              <Button
                disabled={
                  !closeDate ||
                  evaluator === "" ||
                  type === "" ||
                  request == true
                    ? true
                    : false || time == ""
                }
                variant={
                  !closeDate || evaluator === "" || type === "" || time == ""
                    ? "secondary"
                    : "primary"
                }
                onClick={() => {
                  createEvaluation();
                }}
                className={"my-auto"}
              >
                Create
              </Button>
            </CCol>
          </CCardBody>
        )}
      <CCol className={"bg-light"}>
        <div style={{ overflowX: "scroll" }}>
          <div style={{ minWidth: "900px" }}>
            <CDataTable
              items={
                localStorage.getItem("role") === "evaluator"
                  ? // ? evaluations[0]
                    evaluations
                    ? evaluations.filter((item) => {
                        if (item.evaluator.id === localStorage.getItem("id")) {
                          return item;
                        }
                      })
                    : []
                  : // : props.data.evaluations.filter((item) => {
                    //     if (item.evaluator.id === localStorage.getItem("id")) {
                    //       return item;
                    //     }
                    //   })
                    evaluations
                // ? evaluations
                // : props.data.evaluations
              }
              fields={
                localStorage.getItem("role") === "evaluator"
                  ? evaluatorField
                  : EvalFields
              }
              hover
              responsive={true}
              bordered
              size="sm"
              pagination
              scopedSlots={{
                evaluation_type: (item) => {
                  return (
                    <td>
                      {item.evaluation_type === "Telephonic" && (
                        <IoMdCall className="mr-2" size={18}></IoMdCall>
                      )}
                      {item.evaluation_type === "Online Interview" && (
                        <MdOutlineVideoCameraFront
                          className="mr-2"
                          size={18}
                        ></MdOutlineVideoCameraFront>
                      )}
                      {item.evaluation_type === "Face To Face" && (
                        <BsFillPersonLinesFill
                          className="mr-2"
                          size={18}
                        ></BsFillPersonLinesFill>
                      )}
                      {item.evaluation_type === "Online Test" && (
                        <MdTask className="mr-2" size={18}></MdTask>
                      )}
                      {item.evaluation_type}
                    </td>
                  );
                },
                action_date: (item) => {
                  return (
                    <td>{moment(item.action_date).format("DD-MMM-YYYY")}</td>
                  );
                },
                time: (item) => {
                  return <td>{moment(item.time).format("LT")}</td>;
                },
                evaluator: (item) => {
                  return (
                    <td style={{ width: "230px" }}>
                      <EvaluatorName
                        key={item.id}
                        data={{
                          evaluator:
                            item.evaluation_type === "Telephonic"
                              ? telephonicEvaluator
                              : evaluators,
                          name: item.evaluator.name,
                          id: item.id,
                          status:
                            item.evaluation_type === "Telephonic"
                              ? true
                              : false,
                          item,
                        }}
                      ></EvaluatorName>
                    </td>
                  );
                },
                evaluation_status: (item) => {
                  const currentDate = new Date();
                  const todayDate = new Date(currentDate);
                  todayDate.setDate(currentDate.getDate());
                  return (
                    <td>
                      {item.evaluation_type === "Online Test" && (
                        <select
                          // disabled={todayDate <= item.action_date}
                          onChange={(e) => {
                            if (localStorage.getItem("role") == "admin") {
                              swal({
                                title: "Are you sure?",
                                text: "Are you sure about changing Online Test status",
                                type: "warning",
                                icon: "warning",
                                dangerMode: true,
                                buttons: ["No", "Yes"],
                                dangerMode: true,
                                closeOnClickOutside: false,
                              }).then((ok) => {
                                if (ok) {
                                  changeStatus(e.target.value, item.id);
                                } else {
                                  if (
                                    localStorage.getItem("role") != "evaluator"
                                  ) {
                                    setOnlineTestStatusValues([]);
                                    setOnlineTestStatusValues([
                                      "pending",
                                      "attempted",
                                      "not attempted",
                                      "passed",
                                      "failed",
                                    ]);
                                  } else {
                                    setOnlineTestStatusValuesEval([]);
                                    setOnlineTestStatusValuesEval([
                                      "pending",
                                      "not attempted",
                                      "attempted",
                                      "passed",
                                      "failed",
                                    ]);
                                  }
                                }
                              });
                            } else {
                              let value = 0;
                              let defaultValue = 0;
                              for (let i = 0; i < testlevel.length; i++) {
                                if (testlevel[i].value == e.target.value) {
                                  value = testlevel[i].level;
                                }
                                if (
                                  testlevel[i].value == item.evaluation_status
                                ) {
                                  defaultValue = testlevel[i].level;
                                }
                              }
                              if (value >= defaultValue) {
                                swal({
                                  title: "Are you sure?",
                                  text: "Are you sure about changing Online Test status",
                                  type: "warning",
                                  icon: "warning",
                                  dangerMode: true,
                                  buttons: ["No", "Yes"],
                                  dangerMode: true,
                                  closeOnClickOutside: false,
                                }).then((ok) => {
                                  if (ok) {
                                    changeStatus(e.target.value, item.id);
                                  } else {
                                    if (
                                      localStorage.getItem("role") !=
                                      "evaluator"
                                    ) {
                                      setOnlineTestStatusValues([]);
                                      setOnlineTestStatusValues([
                                        "pending",
                                        "attempted",
                                        "not attempted",
                                        "passed",
                                        "failed",
                                      ]);
                                    } else {
                                      setOnlineTestStatusValuesEval([]);
                                      setOnlineTestStatusValuesEval([
                                        "pending",
                                        "not attempted",
                                        "attempted",
                                        "passed",
                                        "failed",
                                      ]);
                                    }
                                  }
                                });
                              } else {
                                if (
                                  localStorage.getItem("role") != "evaluator"
                                ) {
                                  setOnlineTestStatusValues([]);
                                  setTimeout(() => {
                                    setOnlineTestStatusValues([
                                      "pending",
                                      "attempted",
                                      "not attempted",
                                      "passed",
                                      "failed",
                                    ]);
                                  }, 0);
                                } else {
                                  setOnlineTestStatusValuesEval([]);
                                  setTimeout(() => {
                                    setOnlineTestStatusValuesEval([
                                      "pending",
                                      "not attempted",
                                      "attempted",
                                      "passed",
                                      "failed",
                                    ]);
                                  }, 0);
                                }
                                swal({
                                  title: "oops!",
                                  text: "You are not allowed to set the status backward!",
                                  timer: 2000,
                                  icon: "error",
                                  button: false,
                                });
                              }
                            }
                          }}
                          style={{ border: "none" }}
                          class="form-control form-control-sm w-75"
                        >
                          {localStorage.getItem("role") == "evaluator"
                            ? onlineTestStatusValuesEval.map((obj) => {
                                return (
                                  <option
                                    value={obj}
                                    selected={
                                      item.evaluation_status == obj
                                        ? true
                                        : false
                                    }
                                  >
                                    {obj == "pending"
                                      ? "Pending"
                                      : obj == "attempted"
                                      ? "Attempted"
                                      : obj == "not attempted"
                                      ? "Not attempted"
                                      : obj == "passed"
                                      ? "Passed"
                                      : "Failed"}
                                  </option>
                                );
                              })
                            : onlineTestStatusValues.map((obj) => {
                                return (
                                  <option
                                    value={obj}
                                    selected={
                                      item.evaluation_status == obj
                                        ? true
                                        : false
                                    }
                                  >
                                    {obj == "pending"
                                      ? "Pending"
                                      : obj == "attempted"
                                      ? "Attempted"
                                      : obj == "not attempted"
                                      ? "Not attempted"
                                      : obj == "passed"
                                      ? "Passed"
                                      : "Failed"}
                                  </option>
                                );
                              })}
                        </select>
                      )}
                      {item.evaluation_type === "Face To Face" && (
                        <select
                          // disabled={todayDate <= item.action_date}
                          onChange={(e) => {
                            if (localStorage.getItem("role") == "admin") {
                              swal({
                                title: "Are you sure?",
                                text: "Are you sure about changing Face To Face Interview status",
                                type: "warning",
                                icon: "warning",
                                dangerMode: true,
                                buttons: ["No", "Yes"],
                                dangerMode: true,
                                closeOnClickOutside: false,
                              }).then((ok) => {
                                if (ok) {
                                  changeStatus(e.target.value, item.id);
                                } else {
                                  setOnlineStatusValues([]);
                                  setOnlineStatusValues([
                                    "pending",
                                    "not attempted",
                                    "passed",
                                    "failed",
                                  ]);
                                }
                              });
                            } else {
                              let value = 0;
                              let defaultValue = 0;
                              for (let i = 0; i < facelevel.length; i++) {
                                if (facelevel[i].value == e.target.value) {
                                  value = facelevel[i].level;
                                }
                                if (
                                  facelevel[i].value == item.evaluation_status
                                ) {
                                  defaultValue = facelevel[i].level;
                                }
                              }
                              if (value >= defaultValue) {
                                swal({
                                  title: "Are you sure?",
                                  text: "Are you sure about changing Face To Face Interview status",
                                  type: "warning",
                                  icon: "warning",
                                  dangerMode: true,
                                  buttons: ["No", "Yes"],
                                  dangerMode: true,
                                  closeOnClickOutside: false,
                                }).then((ok) => {
                                  if (ok) {
                                    changeStatus(e.target.value, item.id);
                                  } else {
                                    setOnlineStatusValues([]);
                                    setOnlineStatusValues([
                                      "pending",
                                      "not attempted",
                                      "passed",
                                      "failed",
                                    ]);
                                  }
                                });
                              } else {
                                setOnlineStatusValues([]);
                                setTimeout(() => {
                                  setOnlineStatusValues([
                                    "pending",
                                    "not attempted",
                                    "passed",
                                    "failed",
                                  ]);
                                });

                                swal({
                                  title: "oops!",
                                  text: "You are not allowed to set the status backward!",
                                  timer: 2000,
                                  icon: "error",
                                  button: false,
                                });
                              }
                            }
                          }}
                          style={{ border: "none" }}
                          class="form-control form-control-sm w-75"
                        >
                          {OnlineStatusValues.map((obj) => {
                            return (
                              <option
                                value={obj}
                                selected={
                                  item.evaluation_status == obj ? true : false
                                }
                              >
                                {obj == "pending"
                                  ? "Pending"
                                  : obj == "not attempted"
                                  ? "Not attempted"
                                  : obj == "passed"
                                  ? "Passed"
                                  : "Failed"}
                              </option>
                            );
                          })}
                        </select>
                      )}
                      {item.evaluation_type === "Telephonic" && (
                        <select
                          // disabled={todayDate <= item.action_date}
                          onChange={(e) => {
                            if (localStorage.getItem("role") == "admin") {
                              swal({
                                title: "Are you sure?",
                                text: "Are you sure about changing Telephonic Call status",
                                type: "warning",
                                icon: "warning",
                                dangerMode: true,
                                buttons: ["No", "Yes"],
                                dangerMode: true,
                                closeOnClickOutside: false,
                              }).then((ok) => {
                                if (ok) {
                                  changeStatus(e.target.value, item.id);
                                } else {
                                  setTeleStatusValues([]);
                                  setTeleStatusValues(["pending", "completed"]);
                                }
                              });
                            } else {
                              let value = 0;
                              let defaultValue = 0;
                              for (let i = 0; i < Telelevel.length; i++) {
                                if (Telelevel[i].value == e.target.value) {
                                  value = facelevel[i].level;
                                }
                                if (
                                  Telelevel[i].value == item.evaluation_status
                                ) {
                                  defaultValue = Telelevel[i].level;
                                }
                              }
                              if (value >= defaultValue) {
                                swal({
                                  title: "Are you sure?",
                                  text: "Are you sure about changing Telephonic Call status",
                                  type: "warning",
                                  icon: "warning",
                                  dangerMode: true,
                                  buttons: ["No", "Yes"],
                                  dangerMode: true,
                                  closeOnClickOutside: false,
                                }).then((ok) => {
                                  if (ok) {
                                    changeStatus(e.target.value, item.id);
                                  } else {
                                    setTeleStatusValues([]);
                                    setTeleStatusValues([
                                      "pending",
                                      "completed",
                                    ]);
                                  }
                                });
                              } else {
                                setTeleStatusValues([]);
                                setTimeout(() => {
                                  setTeleStatusValues(["pending", "completed"]);
                                }, 0);

                                swal({
                                  title: "oops!",
                                  text: "You are not allowed to set the status backward!",
                                  timer: 2000,
                                  icon: "error",
                                  button: false,
                                });
                              }
                            }
                          }}
                          style={{ border: "none" }}
                          class="form-control form-control-sm w-75"
                        >
                          {teleStatusValues.map((obj) => {
                            return (
                              <option
                                value={obj}
                                selected={
                                  item.evaluation_status == obj ? true : false
                                }
                              >
                                {obj == "pending" ? "Pending" : "Completed"}
                              </option>
                            );
                          })}
                        </select>
                      )}
                      {item.evaluation_type === "Online Interview" && (
                        <select
                          // disabled={todayDate <= item.action_date}
                          onChange={(e) => {
                            if (localStorage.getItem("role") == "admin") {
                              swal({
                                title: "Are you sure?",
                                text: "Are you sure about changing Online Interview status",
                                type: "warning",
                                icon: "warning",
                                dangerMode: true,
                                buttons: ["No", "Yes"],
                                dangerMode: true,
                                closeOnClickOutside: false,
                                showCancelButton: true,
                              }).then((ok) => {
                                if (ok) {
                                  changeStatus(e.target.value, item.id);
                                } else {
                                  setOnlineStatusValues([]);
                                  setOnlineStatusValues([
                                    "pending",
                                    "not attempted",
                                    "passed",
                                    "failed",
                                  ]);
                                }
                              });
                            } else {
                              let value = 0;
                              let defaultValue = 0;
                              for (let i = 0; i < onlinelevel.length; i++) {
                                if (onlinelevel[i].value == e.target.value) {
                                  value = onlinelevel[i].level;
                                }
                                if (
                                  onlinelevel[i].value == item.evaluation_status
                                ) {
                                  defaultValue = onlinelevel[i].level;
                                }
                              }
                              if (value >= defaultValue) {
                                swal({
                                  title: "Are you sure?",
                                  text: "Are you sure about changing Online Interview status",
                                  type: "warning",
                                  icon: "warning",
                                  dangerMode: true,
                                  buttons: ["No", "Yes"],
                                  dangerMode: true,
                                  closeOnClickOutside: false,
                                  showCancelButton: true,
                                }).then((ok) => {
                                  if (ok) {
                                    changeStatus(e.target.value, item.id);
                                  } else {
                                    setOnlineStatusValues([]);
                                    setOnlineStatusValues([
                                      "pending",
                                      "not attempted",
                                      "passed",
                                      "failed",
                                    ]);
                                  }
                                });
                              } else {
                                setOnlineStatusValues([]);
                                setTimeout(() => {
                                  setOnlineStatusValues([
                                    "pending",
                                    "not attempted",
                                    "passed",
                                    "failed",
                                  ]);
                                }, 0);

                                swal({
                                  title: "oops!",
                                  text: "You are not allowed to set the status backward!",
                                  timer: 2000,
                                  icon: "error",
                                  button: false,
                                });
                              }
                            }
                          }}
                          style={{ border: "none" }}
                          class="form-control form-control-sm w-75"
                        >
                          {OnlineStatusValues.map((obj) => {
                            return (
                              <option
                                value={obj}
                                selected={
                                  item.evaluation_status == obj ? true : false
                                }
                              >
                                {obj == "pending"
                                  ? "Pending"
                                  : obj == "not attempted"
                                  ? "Not attempted"
                                  : obj == "passed"
                                  ? "Passed"
                                  : "Failed"}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    </td>
                  );
                },
                Test_link: (item) => {
                  return (
                    <td className="p-1">
                      <Link data={item}></Link>
                    </td>
                  );
                },
                Test: (item) => {
                  if (item.evaluation_type === "Online Test") {
                    return (
                      <td style={{width:"100px"}}>
                        {!item.testId ? (
                          <TestPortal
                            questions={questions}
                            evaluation={item}
                            setEvaluations={setEvaluations}
                            setQuestions={setQuestions}
                          ></TestPortal>
                        ) : (
                          <ViewStatus evaluation={item}></ViewStatus>
                        )}
                        {/* {localStorage.getItem("role") != "evaluator" ? (
                          !item.testId ? (
                            <TestPortal
                              questions={questions}
                              evaluation={item}
                              setEvaluations={setEvaluations}
                              setQuestions={setQuestions}
                            ></TestPortal>
                          ) : (
                            <ViewStatus evaluation={item}></ViewStatus>
                          )
                        ) : item.testId ? (
                          <ViewStatus evaluation={item}></ViewStatus>
                        ) : (
                          ""
                        )} */}
                      </td>
                    );
                  } else {
                    return <td></td>;
                  }
                },
                remark: (item) => {
                  return (
                    <td>
                      <Remark data={item}></Remark>
                    </td>
                  );
                },
              }}
            />
          </div>
        </div>
      </CCol>
    </CCard>
  );
};

export default CreateEvaluationList;

// let msg = [
//   { date: new Date(), remark: "this is a test", user: "alen" },
//   { date: new Date(), remark: "this is a test", user: "alen" },
//   { date: new Date(), remark: "this is a test", user: "alen" },
//   { date: new Date(), remark: "this is a test", user: "alen" },
//   { date: new Date(), remark: "this is a test", user: "alen" },
//   { date: new Date(), remark: "this is a test", user: "alen" },
//   { date: new Date(), remark: "this is a test", user: "alen" },
// ];
function Remark(props) {
  const [show, setShow] = useState(false);
  // const [remark, setRemark] = useState(props.data.evaluation_remark);
  const [remark, setRemark] = useState(props.data.evaluation_remark);
  const [text, setText] = useState("");
  const [scroll, setScroll] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  });
  const deleteRemark = (target) => {
    let editedRemark = remark.filter((obj, index) => {
      if (index !== target) {
        return obj;
      }
    });

    setRemark(editedRemark);
    scrollToBottom();
    setScroll(false);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/deleteEvaluationRemark`,
        {
          remark: editedRemark,
          id: props.data.id,
          user: localStorage.getItem("id"),
        },
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      )
      .then((response) => {
        setRemark(response.data.evaluation_remark);
        setText("");
        // handleClose();
      });
  };
  function addRemark(newRemark) {
    scrollToBottom();
    setScroll(false);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/addEvaluationRemark`,
        {
          remark: [...remark, newRemark],
          id: props.data.id,
          user: localStorage.getItem("id"),
        },
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      )
      .then((response) => {
        // console.log(response.data.evaluation_remark);
        setRemark(response.data.evaluation_remark);
        setText("");
        // handleClose();
      });
  }
  return (
    <>
      {/* <Button variant="primary">
          Launch demo modal
        </Button> */}
      <svg
        onClick={handleShow}
        style={{
          cursor: "pointer",
          color: remark ? (remark[0] ? "#ef233c" : "#8d99ae") : "#8d99ae",
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="currentColor"
        className={"bi"}
        viewBox="0 0 16 16"
      >
        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
        <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
      </svg>
      <Modal centered backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <div className="d-flex">
              <div>
                Remark for{" "}
                <span className="text-primary">{props.data.can_id.name}'s</span>{" "}
                {props.data.evaluation_type === "Telephonic" &&
                  "Telephonic Call"}
                {props.data.evaluation_type === "Online Interview" &&
                  "Online Interview"}
                {props.data.evaluation_type === "Face To Face" &&
                  "Face To Face Interview"}
                {props.data.evaluation_type === "Online Test" && "Online Test"}
              </div>
              {/* <div className="mx-1 text-primary">
                {props.data.can_id.name}'s
              </div>
              <div>Evalution</div> */}
            </div>
            <div className="d-flex">
              {props.data.jd_id.title}-
              <span className="text-primary">
                {props.data.jd_id.custome_jd_id}
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="w-100  flex-column rounded p-1 mb-2"
            style={{
              height: "auto",
              maxHeight: "300px",
              backgroundColor: "#ffffff",
              overflowY: "scroll",
              display: "inline-block",
            }}
          >
            {remark.map((obj, index) => {
              let str2 = "";
              if (obj.role) {
                const str = obj.role;
                str2 = str.charAt(0).toUpperCase() + str.slice(1);
              }

              return (
                <div className="w-100 d-flex">
                  <div
                    className={
                      localStorage.getItem("id") === obj.userId
                        ? "mb-2 px-1 rounded ml-auto"
                        : "mb-2 px-1 rounded"
                    }
                    style={{
                      backgroundColor:
                        localStorage.getItem("id") === obj.userId
                          ? "#e9f5db"
                          : "#dee2ff",
                      minWidth: "70%",

                      maxWidth: "80%",
                    }}
                    key={index}
                  >
                    <div className=" d-flex justify-content-between align-items-center">
                      <small className=" text-primary ">
                        {obj.user}-{str2}
                      </small>

                      {localStorage.getItem("role") === "admin" ? (
                        <TiDeleteOutline
                          onClick={() => {
                            deleteRemark(index);
                          }}
                          className="cross"
                          style={{ cursor: "pointer" }}
                          size={19}
                        ></TiDeleteOutline>
                      ) : localStorage.getItem("id") === obj.userId ? (
                        <TiDeleteOutline
                          className="cross"
                          onClick={() => {
                            deleteRemark(index);
                          }}
                          style={{ cursor: "pointer" }}
                          size={19}
                        ></TiDeleteOutline>
                      ) : (
                        <></>
                      )}
                    </div>
                    <p className=" mb-0 mt-0 pl-1 pt-0 ">
                      <small
                        className="mt-0 pl-1 pt-0"
                        style={{ color: "#231942" }}
                      >
                        {obj.remark}
                      </small>
                    </p>
                    <div
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "auto",
                      }}
                    >
                      <small
                        style={{ fontSize: ".7rem" }}
                        className="mt-0 text-end ml-auto"
                      >
                        {moment(obj.date).startOf().fromNow()}
                      </small>
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              style={{ width: "100%", height: "2px" }}
              ref={messagesEndRef}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex ">
          <CRow className={" w-100"}>
            <CCol className={"col-10 col-sm-10 p-0 "}>
              <Form.Control
                value={text}
                onChange={(e) => {
                  setText(e.target.value.trimStart());
                }}
                as="textarea"
                rows={1}
              />
            </CCol>

            {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
            <CCol className={"col-sm-1"}>
              <Button
                disabled={text ? false : true}
                variant="primary"
                onClick={(e) => {
                  setScroll(true);
                  let date = Date.now();
                  if (remark[0]) {
                    setRemark((obj) => {
                      // let date= new Date()
                      return [
                        ...obj,
                        {
                          date: date,
                          remark: text,
                          user: localStorage.getItem("id"),
                          userId: localStorage.getItem("id"),
                          role: localStorage.getItem("role"),
                        },
                      ];
                    });
                  } else {
                    setRemark([
                      {
                        date: date,
                        remark: text,
                        user: localStorage.getItem("id"),
                        userId: localStorage.getItem("id"),
                        role: localStorage.getItem("role"),
                      },
                    ]);
                  }

                  addRemark({
                    date: date,
                    remark: text,
                    user: localStorage.getItem("id"),
                    userId: localStorage.getItem("id"),
                    role: localStorage.getItem("role"),
                  });
                }}
              >
                <IoIosSend size={22}></IoIosSend>
              </Button>
            </CCol>
          </CRow>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function Link(props) {
  const [newLink,setNewLink] = useState(props.data.link)
  const [link, setLink] = useState(props.data.link);
  const [linkDel, setLinkDel] = useState(props.data.link);
  let [edit, setEdit] = useState(false);
  function addLink(id) {
    if (link) {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/addLink`,
          {
            link,
            id,
          },
          {
            headers: { authorization: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          setLinkDel(link);
          setNewLink(link)
          handleClose();
          swal({
            title: "Success!",
            text: "Link Added",
            timer: 1500,
            icon: "success",
            button: false,
          });
        });
    }
  }
  function removeLink(id) {
    if (link) {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/removeLink`,
          {
            id,
          },
          {
            headers: { authorization: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          setLink("");
          setLinkDel("");
          setNewLink('')
          swal({
            title: "Success!",
            text: "Link Removed",
            timer: 1500,
            icon: "success",
            button: false,
          });
          
        });
    }
  }
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false)
  };
  const handleShow = () => setShow(true);
  return (
    <div className="d-flex ">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Test Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CInput
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
            }}
            size="sm"
            className={""}
            id="Exp"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{
              setLink(newLink)
              handleClose()
            }}>
            Close
          </Button>
          {linkDel && (
            <Button
              disabled={ link == newLink ? false : true}
              variant="primary"
              onClick={() => {
                removeLink(props.data.id);
                handleClose();
              }}
            >
              Delete
            </Button>
          )}
          
          <Button
            disabled={!link || link == newLink ? true : false}
            variant="primary"
            onClick={() => {
              addLink(props.data.id);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {props.data.evaluation_type === "Online Test" && (
        <a
          target="_blank"
          style={{ pointerEvents: !newLink ? "none" : "" }}
          className="my-auto"
          href={newLink}
        >
          {newLink ? "Open Link" : "Add Link"}
        </a>
      )}
      {/* {props.data.evaluation_type === "Online Test" && edit && (
        <CInput
          value={link}
          onChange={(e) => {
            setLink(e.target.value);
          }}
          disabled={props.data.evaluation_type === "Online Test" ? false : true}
          size="sm"
          className={""}
          id="Exp"
        />
      )} */}
      {props.data.evaluation_type === "Online Test" && (
        <CButton
          disabled={props.data.evaluation_type === "Online Test" ? false : true}
          className={"ml-2 bg-primary text-white py-auto"}
          size="sm"
          onClick={() => {
            handleShow();
            setEdit(true);
          }}
        >
          <MdEditSquare size={19}></MdEditSquare>
        </CButton>
      )}
      {/* {props.data.evaluation_type === "Online Test" && (
        <CButton
          disabled={props.data.evaluation_type === "Online Test" ? false : true}
          className={"ml-1 bg-primary text-white py-auto"}
          size="sm"
          onClick={() => {
            removeLink(props.data.id);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            class="bi bi-trash3"
            viewBox="0 0 16 16"
          >
            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
          </svg>
        </CButton>
      )} */}
    </div>
  );
}

function EvaluatorName(props) {
  const [permission, setPermission] = useState(
    props.data.item.evaluation_status
  );
  useEffect(() => {
    setPermission(props.data.item.evaluation_status);
  }, [props.data.item]);
  const [edit, setEdit] = useState(true);
  const str = props.data.name;
  const str2 = str.charAt(0).toUpperCase() + str.slice(1);
  const [name, setName] = useState(str2);
  const [evaluator, setEvaluator] = useState("");
  const [select, setSelect] = useState(true);
  function createEvaluation() {
    if (evaluator) {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/editAssignedEvaluator`,
          {
            evaluator,
            id: props.data.id,
          },
          {
            headers: { authorization: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          const str = res.data.name;
          const str2 = str.charAt(0).toUpperCase() + str.slice(1);
          setName(str2);
          setEvaluator("");
          swal({
            title: "Success!",
            text: "Evaluator Changed",
            timer: 1500,
            icon: "success",
            button: false,
          });
        });
    } else {
      setEvaluator("");
    }
  }

  return (
    <CRow className={""}>
      <CCol className={" p-0"}>
        {edit && (
          <select
            disabled={edit}
            onChange={(e) => {}}
            style={{ border: "none" }}
            className="form-control m-0 form-control-sm w-100 "
          >
            <option className="w-100">{name}</option>
          </select>
        )}
        {!edit && (
          <select
            disabled={edit}
            onChange={(e) => {
              setEvaluator(e.target.value);
              setSelect(false);
            }}
            style={{ border: "none" }}
            className="form-control m-0 form-control-sm w-100 "
          >
            {select && (
              <option hidden selected={select} value={""}>
                {name}
              </option>
            )}
            {props.data.evaluator.map((obj) => {
              if (props.data.status) {
                if (obj.role === "hr") {
                  const str = obj.name;
                  const str2 = str.charAt(0).toUpperCase() + str.slice(1);
                  return (
                    <option className="w-100" value={obj.id}>
                      {str2}
                    </option>
                  );
                }
              } else if (!props.data.status) {
                const str = obj.value;
                const str2 = str.charAt(0).toUpperCase() + str.slice(1);
                return (
                  <option className="w-100" value={obj.id}>
                    {str2}
                  </option>
                );
              }
            })}
          </select>
        )}
      </CCol>
      <CCol xs="4">
        {edit ? (
          <CButton
            disabled={
              localStorage.getItem("role") == "admin"
                ? false
                : props.data.item.evaluation_status == "pending"
                ? false
                : true
            }
            className={"bg-primary text-white"}
            size="sm"
            onClick={() => {
              setEdit(false);
            }}
          >
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              class="bi bi-pencil-fill"
              viewBox="0 0 16 16"
              style={{ cursor: "pointer" }}
            >
              <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
            </svg> */}
            <MdEditSquare size={19}></MdEditSquare>
          </CButton>
        ) : (
          <CButton
            className={"bg-primary text-white"}
            size="sm"
            variant="secondary"
            onClick={() => {
              setEdit(true);
              setSelect(true);
              createEvaluation();
            }}
          >
            {/* <svg
              style={{ cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              class="bi bi-check-lg"
              viewBox="0 0 16 16"
            >
              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
            </svg> */}
            <ImCheckmark size={19}></ImCheckmark>
          </CButton>
        )}
      </CCol>
    </CRow>
  );
}
