import { CButton } from "@coreui/react";
import "./style.css";
export default function SomeThingWentWrong() {
  function retry() {
    window.location.reload();
  }
  return (
    <>
      <div class="page-404">
        <div class="outer">
          <div class="middle ">
            <div class=" d-flex flex-column col-12">
              {/* <div class="inner-circle">
                <i class="fa fa-cogs"></i>
                <span>500</span>
              </div>
              <span class="inner-status">Opps! Internal Server Error!</span> */}
              <div className="four_zero_four_bg "></div>
              <div className="col-md-6 col-12 px-0  d-flex flex-column mx-auto align-items-center text-center message">
                <span className="col-12 ">
                  No internet connection or something went wrong. Please check
                  your network connection.
                </span>
                <CButton
                  className={"col-3 col-sm-4 mt-1"}
                  onClick={retry}
                  color="secondary"
                >
                  Retry
                </CButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
