import { CCol, CRow } from "@coreui/react";
import "./style.css";
import dinner from "./images/romantic-dinner-setting.png";
import game from "./gallery/tech.png";
import beach from "./gallery/MicrosoftTeams-image.png";
import birthday from './gallery/birth.png'
import officegame from './gallery/img4.png'
export default function Activies() {
  return (
    <CRow className={"bg-white mx-auto pb-5"} style={{ height: "auto" }}>
      <CCol className={"py-4 text-center"} style={{color:"black"}} xs="12">
        <p className="h3 Goldman">Life at Mobigic</p>
      </CCol>
      <div className="container " style={{color:"black"}}>
        <CRow className={" position-relative activity-parent mx-auto"}>
          <div className="activity"></div>
          <CCol
            md="4"
            xs="10"
            className={"d-flex mx-auto mx-md-0 align-items-center"}
          >
            <img
              src={dinner}
              className=""
              alt=""
              style={{
                width: "100%",
                objectFit: "contain",
                border: "10px solid white",
                borderRadius: "6px",
              }}
            />
          </CCol>
          <CCol xs="12" md="8" className={"my-auto"}>
            <CCol xs="12" className={"mx-auto p-5"}>
              <p className="Nunito h5 text-center font-weight-bold">Festival Celebration </p>
              <p style={{ textAlign: "justify" }}>
                <p className="Nunito">
                  Our Office Festival Culture is a reflection of our diverse and
                  inclusive workplace. It's a time to appreciate different
                  traditions, share experiences, and foster a sense of
                  belonging. Through vibrant decorations, cultural performances,
                  and meaningful conversations, we celebrate our collective
                  identity and create a harmonious work environment. Embrace the
                  richness of our Office Festival Culture!
                </p>
              </p>
            </CCol>
          </CCol>
        </CRow>
        <CRow
          className={
            "d-block d-md-none mt-5 position-relative activity-parent mx-auto"
          }
        >
          <div className="activity bg-primary"></div>
          <CCol
            md="4"
            xs="10"
            className={"d-flex mx-auto mx-md-0 align-items-center"}
          >
            <img
              src={game}
              className=""
              alt=""
              style={{
                width: "100%",
                objectFit: "contain",
                border: "10px solid white",
                borderRadius: "6px",
              }}
            />
          </CCol>
          <CCol xs="12" md="8" className={"my-auto"}>
            <CCol xs="12" className={"mx-auto p-5 text-white"}>
              <p className="Nunito h5 text-center font-weight-bold">Tech Friday </p>
              <p style={{ textAlign: "justify" }}>
                <p className="Nunito">
                  In our Office, the Weekly Technical Discussion is a recurring
                  activity where employees gather every Friday to exchange
                  knowledge, discuss industry trends, solve problems, and
                  enhance their skills. It promotes learning, collaboration, and
                  innovation, fostering a dynamic environment for professional
                  growth and development.
                </p>
              </p>
            </CCol>
          </CCol>
        </CRow>

        <CRow
          className={"d-none d-md-flex position-relative mt-3"}
          style={{ height: "20rem" }}
        >
          <div
            className="position-absolute bg-primary"
            style={{
              height: "100%",
              width: "80%",
              left: 0,
              //   backgroundColor: "#F2F2F2",
              borderRadius: "6px",
            }}
          ></div>

          <CCol className={"my-auto"}>
            <CCol xs="11" className={"ml-auto p-5 text-white"}>
              <p className="Nunito h5 text-center font-weight-bold">Tech Friday </p>
              <p style={{ textAlign: "justify" }}>
                <p className="Nunito">
                  In our Office, the Weekly Technical Discussion is a recurring
                  activity where employees gather every Friday to exchange
                  knowledge, discuss industry trends, solve problems, and
                  enhance their skills. It promotes learning, collaboration, and
                  innovation, fostering a dynamic environment for professional
                  growth and development.
                </p>
              </p>
            </CCol>
          </CCol>
          <CCol xs="4" className={"d-flex align-items-center"}>
            <img
              src={game}
              className=""
              alt=""
              style={{
                width: "100%",
                objectFit: "contain",
                border: "10px solid white",
                borderRadius: "6px",
              }}
            />
          </CCol>
        </CRow>
      </div>
      <div className="container mt-5">
        <CRow className={" position-relative activity-parent mx-auto"}>
          <div className="activity"></div>
          <CCol
            md="4"
            xs="10"
            className={"d-flex mx-auto mx-md-0 align-items-center"}
          >
            <img
              src={beach}
              className=""
              alt=""
              style={{
                width: "100%",
                objectFit: "contain",
                border: "10px solid white",
                borderRadius: "6px",
              }}
            />
          </CCol>
          <CCol xs="12" md="8" className={"my-auto"} style={{color:"black"}}>
            <CCol xs="12" className={"mx-auto p-5"}>
              <p className="Nunito h5 text-center font-weight-bold">Office Vacation Tours </p>
              <p style={{ textAlign: "justify" }}>
                <p className="Nunito">
                  Escape and unwind with our Office Vacation Culture! Embrace
                  relaxation and rejuvenation as we encourage work-life balance.
                  From team retreats to tropical getaways, we prioritize
                  employee well-being by providing opportunities to recharge and
                  bond. Explore new horizons, create lasting memories, and
                  return refreshed to continue making great strides together.
                  Bon voyage!
                </p>
              </p>
            </CCol>
          </CCol>
        </CRow>
        <CRow
          className={
            "d-block d-md-none mt-5 position-relative activity-parent mx-auto"
          }
        >
          <div className="activity bg-primary"></div>
          <CCol
            md="4"
            xs="10"
            className={"d-flex mx-auto mx-md-0 align-items-center"}
          >
            <img
              src={officegame}
              className=""
              alt=""
              style={{
                width: "100%",
                objectFit: "contain",
                border: "10px solid white",
                borderRadius: "6px",
              }}
            />
          </CCol>
          <CCol xs="12" md="8" className={"my-auto"}>
            <CCol xs="12" className={"mx-auto p-5 text-white"}>
              <p className="Nunito h5 text-center font-weight-bold">Office Games </p>
              <p style={{ textAlign: "justify" }}>
                <p className="Nunito">
                  In our Office Games Culture, fun and friendly competition take
                  center stage. Whether it's team-building challenges, board
                  games, or sports tournaments, we foster a lively and engaging
                  atmosphere. It's a chance to build connections, boost morale,
                  and encourage teamwork. Let the games begin and let the spirit
                  of camaraderie guide us to victory!
                </p>
              </p>
            </CCol>
          </CCol>
        </CRow>

        <CRow
          className={"d-none d-md-flex position-relative mt-3"}
          style={{ height: "20rem" }}
        >
          <div
            className="position-absolute bg-primary"
            style={{
              height: "100%",
              width: "80%",
              left: 0,
              //   backgroundColor: "#F2F2F2",
              borderRadius: "6px",
            }}
          ></div>

          <CCol className={"my-auto"}>
            <CCol xs="11" className={"ml-auto p-5 text-white"} >
              <p className="Nunito h5 text-center font-weight-bold">Office Games </p>
              <p style={{ textAlign: "justify" }}>
                <p className="Nunito">
                  In our Office Games Culture, fun and friendly competition take
                  center stage. Whether it's team-building challenges, board
                  games, or sports tournaments, we foster a lively and engaging
                  atmosphere. It's a chance to build connections, boost morale,
                  and encourage teamwork. Let the games begin and let the spirit
                  of camaraderie guide us to victory!
                </p>
              </p>
            </CCol>
          </CCol>
          <CCol xs="4" className={"d-flex align-items-center"}>
            <img
              src={officegame}
              className=""
              alt=""
              style={{
                width: "100%",
                objectFit: "contain",
                border: "10px solid white",
                borderRadius: "6px",
              }}
            />
          </CCol>
        </CRow>
      </div>
      <div className="container mt-5">
        <CRow className={" position-relative activity-parent mx-auto"}>
          <div className="activity"></div>
          <CCol
            md="4"
            xs="10"
            className={"d-flex mx-auto mx-md-0 align-items-center"}
          >
            <img
              src={birthday}
              className=""
              alt=""
              style={{
                width: "100%",
                objectFit: "contain",
                border: "10px solid white",
                borderRadius: "6px",
              }}
            />
          </CCol>
          <CCol xs="12" md="8" className={"my-auto"}>
            <CCol xs="12" className={"mx-auto p-5"} style={{color:"black"}}>
              <p className="Nunito h5 text-center font-weight-bold" >Birthday Celebration</p>
              <p style={{ textAlign: "justify" }}>
                <p className="Nunito">
                  Birthdays are special, and in our Office Birthday Celebration
                  Culture, we go the extra mile to make them unforgettable. From
                  surprise parties to personalized gifts, we celebrate each team
                  member's milestone with joy and appreciation. Let's come
                  together, share laughter, and make every birthday a cherished
                  moment in our work-family.
                </p>
              </p>
            </CCol>
          </CCol>
        </CRow>
      </div>
    </CRow>
  );
}
