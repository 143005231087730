import { CCol, CContainer, CRow } from "@coreui/react";
import logo from "./mobigic_sticker_path (1).svg";
import { useHistory } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import "./zohoStyles.css";
import { useEffect } from "react";
export default function Opening() {
  let history = useHistory();
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://static.zohocdn.com/recruit/embed_careers_site/javascript/v1.1/embed_jobs.js";
    // document.body.appendChild(script);
    script.async = true;
    script.onload = () => {
      window.rec_embed_js.load({
        widget_id: "rec_job_listing_div",
        page_name: "Careers",
        source: "CareerSite",
        site: "https://mobigic.zohorecruit.in",
        brand_color: "#0F77EA",
        empty_job_msg: "No current Openings",
        extra_fields: [],
      });
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <CContainer style={{ minHeight: "100vh", maxHeight: "auto" }}>
      <div className="d-flex  justify-content-center align-items-center p-3 w-100">
        <img
          style={{ width: "23%", cursor: "pointer" }}
          src={logo}
          alt=""
          className="mx-1"
          onClick={() => {
            history.push("/");
          }}
        />
        {/* <h4
        style={{ textAlign: "center" }}
        className="logoName d-flex align-items-center mt-1 mx-1"
      >
        {!showModal ? (
          <h4 className=" logoName mt-1" style={{ color: "#0f77ea" }}>
            Mobigic Technologies Pvt Ltd.
          </h4>
        ) : (
          <h5 className=" logoName mt-1" style={{ color: "#0f77ea" }}>
            Mobigic Technologies Pvt Ltd.
          </h5>
        )}
      </h4> */}
      </div>
      <hr className="my-1"></hr>
      <CRow
        className={"mx-auto pt-2   rounded-top"}
        style={{ width: "100%" }}
        // style={{ width: "95%",background:" rgba(255,255,255,0.8)" }}
      >
        {/* <p
          className="my-0    ml-md-3  pt-0 pb-0   rounded-top"
          style={{ cursor: "pointer" }}
        >
          <BsArrowLeftShort
            style={{ cursor: "pointer" }}
            className="p-0"
            size={35}
          ></BsArrowLeftShort>{" "}
          Current openings
        </p> */}

        {/* <style>
	.rec_filter_cls, .rec-what-where {display: none !important;}
	.zrsite_Job_Description {display: none !important;}
	.rec-group {
		display: grid;
  		grid-template-columns: repeat(3, 1fr);
  		grid-auto-rows: 130px;
  		column-gap: 20px;
  		row-gap: 20px;
	}
	ul .rec-job-info {cursor: pointer; border-radius: 10px; box-shadow: 0 .125rem .25rem rgba(0,0,21,.075) !important;}
	.embed_jobs_with_style_3 .rec-group .rec-job-info .zr_fillayout_info {
  		width: 100% !important;
	}
	.zrsite_Location {margin-top: 15px !important;}
	.embed_jobs_head2 {background-image: url("https://img.freepik.com/free-vector/gradient-white-color-background-abstract-modern_343694-2125.jpg?w=826&t=st=1684468861~exp=1684469461~hmac=d1e24749c7d185ec959c5b491fee5c4a2029209be37c608653d5f1cfda4e7196");
background-size: cover;}
	.rec-job-title {font-size: 14px !important;}
	.zrsite_Location {font-size: 14px !important;}
</style> */}

        <CCol
          style={{
            maxHeight: "74vh",
            overflowY: "scroll",
            padding: 0
            // display: width < 721 ? "" : cardDisplay,
            // gridTemplateColumns: "1fr 1fr 1fr",
            // columnGap: "10px",
            // rowGap: "10px",
          }}
          className={" embed_jobs_with_style_3"}
        >
          {/* <div class="embed_jobs_head embed_jobs_with_style_3"> */}
            {/* <div class="embed_jobs_head2">
              <div class="embed_jobs_head3"> */}
                <div id="rec_job_listing_div"> </div>
                {/* <script type="text/javascript" src="https://static.zohocdn.com/recruit/embed_careers_site/javascript/v1.1/embed_jobs.js"></script>
			<script type="text/javascript">
			rec_embed_js.load({
				widget_id:"rec_job_listing_div",
				page_name:"Careers",
				source:"CareerSite",
				site:"https://mobigic.zohorecruit.in",
				brand_color: "#0F77EA",
				empty_job_msg:"No current Openings",
				extra_fields: []
			});d
			</script> */}
              {/* </div>
            </div> */}
          {/* </div> */}

          {/* <div id="rec_job_listing_div"> </div> */}
        </CCol>
        {/* <div class="embed_jobs_head embed_jobs_with_style_3">
          <div class="embed_jobs_head2">
            <div class="embed_jobs_head3">
              <script type="text/javascript" src="https://static.zohocdn.com/recruit/embed_careers_site/javascript/v1.1/embed_jobs.js"></script>
              <div id="rec_job_listing_div"></div>;
              <script type="text/javascript">
			rec_embed_js.load({
				widget_id:"rec_job_listing_div",
				page_name:"Careers",
				source:"CareerSite",
				site:"https://mobigic.zohorecruit.in",
				brand_color: "#0F77EA",
				empty_job_msg:"No current Openings",
				extra_fields: []
			});
			</script>
            </div>
          </div>
        </div> */}
      </CRow>
    </CContainer>
  );
}
