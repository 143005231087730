import { CButton, CCol, CRow } from "@coreui/react";
import img from "./images/mobigic_sticker_path (1).svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function Header() {
  let history = useHistory();
  return (
    // <div className="w-100 mx-auto">
      <CRow
        className={"py-0 border-bottom bg-white position-fixed col-12 mx-auto "}
        style={{ zIndex: 3,height:"10vh"}}

      >
        <CCol xs="6 " style={{ height: "8vh" }} className={"my-auto"}>
          <img
            src={img}
            style={{ height: "100%", objectFit: "contain" ,cursor:"pointer"}}
            className="ml-3"
            alt=""
            onClick={()=>{
              history.push('/welcome')
            }}
          />
        </CCol>
        <CCol xs="6" className={"ml-auto  d-flex justify-content-end"}>
          {/* <CButton
            onClick={() => {
              history.push("/openings");
            }}
            className={" my-2 btn btn-primary btn-sm mr-3"}
          >
            Apply for job
          </CButton> */}
        </CCol>
      </CRow>
    // </div>
  );
}
