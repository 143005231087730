import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import img from "./mobigic_sticker_path (1).svg";

import {
  CCreateElement,
  CSidebar,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CToggler,
} from "@coreui/react";
import "./style.css";
import "./sideBarStyle.scss";
import { RxCross2 } from "react-icons/rx";
// sidebar nav config
import AdminNavigation from "./AdminNav";
import { useHistory } from "react-router-dom";
import { sideBarContext } from "src/UserContext";

const TheSidebar = () => {
  const [close,setClose]=useState(false)
  useEffect(() => {
    if (window.innerWidth < 1001) {
      setClose(true);
    }
  }, []);
  const history = useHistory();
  const dispatch = useDispatch();
  const { sideBar, setSideBar } = useContext(sideBarContext);
  return (
    <CSidebar
      show={sideBar}
      color="white"
      // className={"sidebar-nav "}
      onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}
      colorScheme="light"
      
    >
      <div className=" px-3 py-2">
        <img src={img} style={{ objectFit: "contain" ,width:"100%"}} alt="" />
      </div>

      {/* <CSidebarBrand className="d-md-down-none bg-light" to="/"> */}
      {/* <CIcon
          className="c-sidebar-brand-full"
          name="logo-negative"
          height={35}
        />
        <CIcon
          className="c-sidebar-brand-minimized"
          name="sygnet"
          height={35}
        /> */}
      {/* <img src="/mobigic_logo.svg" alt="" /> */}
      {/* <div
        style={{ cursor: "pointer" }}
        className="d-flex justify-content-center align-items-center p-1 mt-1"
       
      >
        <img
          style={{ width: "15%" }}
          src="https://res.cloudinary.com/dcytixl43/image/upload/v1679634232/mobigic_logo_cqhvfe.svg"
          alt=""
          className="mx-1"
          onClick={() => {
            history.push("/home");
          }}
        />
     
        <h4 className="mt-1 logoName" style={{ color: "#ffca0e" }}  onClick={() => {
          history.push("/home");
        }}>
          HR
        </h4>{" "}
        <h4 className=" logoName mt-1" style={{ color: "#2874f0" }}  onClick={() => {
          history.push("/home");
        }}>
          Mobigic
        </h4>
       
        <div className="ml-auto ">
          <RxCross2
          size={20}
            inHeader
   
            className="ml-md-3 d-lg-none text-Secondary"
            style={{color:"black"}}
            onClick={() => {
              if (sideBar) {
                setSideBar(false);
              } else {
                setSideBar(true);
              }
            }}
          />
        </div>
      </div> */}
      {/* </CSidebarBrand> */}
      <CSidebarNav className={"nav-item"}>
        <CCreateElement
          items={AdminNavigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      {/* <CSidebarMinimizer className="c-d-md-down-none" /> */}

      {close&&<button class="c-sidebar-minimizer c-brand-minimizer d-flex" onClick={()=>{
        setSideBar(false)
      }} type="button"></button>}
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
