import { CButton, CCol, CRow } from "@coreui/react";
import "./style.css";
import jobs from "./images/jobs.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Job() {
  let history = useHistory();
  return (
    <CRow
      className={"bg-white text-center pt-5 mx-auto"}
      style={{ color: "black" }}
    >
      <CCol xs="12" className={"mb-3"}>
        <p className="h3 Goldman">Join Our Team</p>
      </CCol>
      <CCol
        md="6"
        style={{ height: "50vh" }}
        // style={{ backgroundColor: "#0f77ea",height:"40vh" }}
        className={" d-none d-md-block"}
      >
        <div className="w-100 h-100">
          <img
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            src={jobs}
          ></img>
        </div>
      </CCol>
      <CCol className={"text center Nunito"}>
        <div className="w-100 my-auto h-100 d-flex flex-column  align-items-center justify-content-center">
          <p className="w-75 mx-auto " style={{ textAlign: "justify",fontWeight:"bold" }}>
            Join our organization and be part of an incredible team where your
            skills and talents will flourish. Together, let's create a rewarding
            and successful journey. We look forward to welcoming you aboard!
          </p>
          <button
            onClick={() => {
              history.push({pathname:"/openings",state:true});
            }}
            className="applyButton shadow-lg bg-primary text-white mt-5 mb-3"
          >
            Apply Job
          </button>
        </div>
      </CCol>
    </CRow>
  );
}
