import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
};

export const CandidateSlice = createSlice({
  name: "remark",
  initialState,
  reducers: {
    changeRemark: (state,action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeRemark } = CandidateSlice.actions;

export default CandidateSlice.reducer;
