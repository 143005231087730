import { createSlice } from '@reduxjs/toolkit'


const UserSlice = createSlice({
    name: 'userInfo',
    initialState: {
        token:"",
        role:"",
        id:"",
    },
    reducers: {
      
        setUserInfo: {
            reducer(state, action) {
                state.token = action.payload.token
                state.role = action.payload.role
                state.id = action.payload.id
            }
        },
        
    }
})


export default UserSlice.reducer;


export const {
    setUserInfo

} =UserSlice.actions;

export const selectQuestions = (state) => state.notelist;