  
import { configureStore } from '@reduxjs/toolkit'

import UserSlice from './UserSlice'
import CandidateSlice from './CandidateSlice';
import RemarkSlice from './RemarkSlice';
import persistReducer from 'redux-persist/es/persistReducer';
import  storage from 'redux-persist/lib/storage';
import authReducer from './auth'
import { persistStore } from 'redux-persist';
const persistConfig = {
    key: 'root',
    storage, 
  };
  
  const persistedReducer = persistReducer(persistConfig, authReducer);
  
export const store = configureStore({
    reducer: {
        CandidateSlice:CandidateSlice,
        UserSlice:UserSlice,
        Remark:RemarkSlice,
        persist:persistedReducer 
        
     
    }
})

export const persistor = persistStore(store);