import { CButton, CRow } from "@coreui/react";
import Banner from "./Banner";
import Header from "./Header";
import CoreValues from "./CoreValues";
import Brief from "./Brief";
import Activies from "./Activities";
import Technologies from "./Technologies";
import Contact from "./Contact";
import Gallery from "./Gallery";
import { useEffect, useState } from "react";
import { useScrollDirection } from "use-scroll-direction";
import Job from "./Job";
import "./style.css";
import { AiFillDownCircle } from "react-icons/ai";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

export default function LandingPage() {
  const [scrollDown, setScrollDown] = useState("block");
  const [scrollId, setScrollId] = useState("2");
  const {
    scrollDirection,
    isScrolling,
    isScrollingUp,
    isScrollingDown,
    isScrollingLeft,
    isScrollingRight,
  } = useScrollDirection();

  // useEffect(() => {
  //   const element = document.getElementById("3");
  //   if (isScrolling && scrollDown == "block") {
  //     setScrollDown("none");
  //   }

  //   // element.scrollIntoView({behavior:"smooth"});
  // }, [scrollDirection]);
  // // inside component:

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, { passive: true });

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // });
  useEffect(() => {
    const handleResize = () => {
    };

    window.addEventListener("scroll", handleResize);

    return () => {
      window.removeEventListener("scroll", handleResize);
    };
  }, []);

  return (
    <div className="w-100">
      <div
        className="position-absolute  scrollDownMobile"
        style={{
          zIndex: 3,
          height: "70px",
          width: "100%",
          display: scrollDown,
        }}
      >
        <div className={"w-100   h-100 d-flex justify-content-center"}>
          <div
            className="scrollDown  text-center"
            style={{ height: "40px", width: "auto" }}
          >
            <AiFillDownCircle className="w-100 h-100  text-primary"></AiFillDownCircle>
            {/* Scroll down */}
          </div>
        </div>
      </div>

      {parseInt(scrollId) <= 8 && (
        <div className="d-none d-md-block downButton ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="55"
            height="55"
            fill="currentColor"
            class="bi bi-arrow-right-circle-fill "
            viewBox="0 0 16 16"
            className={`m-0 shadow-lg downButton ${
              scrollId == "4" ? "text-warning" : "text-warning"
            }`}
            style={{
              position: "fixed",
              zIndex: 5,
              top: "85%",

              cursor: "pointer",
              borderRadius: "50%",
              backgroundColor: "white",
            }}
            onClick={() => {
              let element = document.getElementById(scrollId);
              element.scrollIntoView({ behavior: "smooth" });

              setScrollId((id) => {
                let count = parseInt(id);
                count++;
                return "" + count;
              });
            }}
          >
            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
          </svg>
        </div>
      )}
      {parseInt(scrollId) > 8 && (
        <div className="d-none d-md-block  upArrowButton">
          <svg
            style={{
              position: "fixed",
              zIndex: 5,
              top: "85%",
              left: "92%",
              cursor: "pointer",
              color: "#ffc107",
              borderRadius: "50%",
              backgroundColor: "white",
            }}
            id="upArrowButton"
            className="shadow-lg  text-warning"
            onClick={() => {
              let element = document.getElementById("1");
              element.scrollIntoView({ behavior: "smooth" });

              setScrollId("2");
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="55"
            height="55"
            fill="currentColor"
            class="bi bi-arrow-up-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
          </svg>
        </div>
      )}

      <Header></Header>
      <div
        onScroll={() => {
          if (scrollDown == "block") {
            setScrollDown("none");
          }
        }}
        style={{
          position: "relative",
          top: "10vh",
          height: "90vh",
          overflowY: "scroll",
        }}
      >
        <div className="banner w-100" id="1">
          <Banner></Banner>
        </div>
        <div className="w-100" id="2">
          <CoreValues></CoreValues>
        </div>

        <div className="w-100" id="3">
          <Brief></Brief>
        </div>
        <div className="w-100" id="4">
          <Technologies></Technologies>
        </div>
        <div className="w-100" id="5">
          <Activies></Activies>
        </div>
        <div className="w-100" id="6">
          <Gallery></Gallery>
        </div>
        <div className="w-100" id="7">
          <Job></Job>
        </div>
        <div className="w-100" id="8">
          <Contact></Contact>
        </div>
      </div>
    </div>
  );
}
