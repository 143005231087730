import { CSpinner } from "@coreui/react";


export default function Loading(props){
    return(
        <div style={{width:"100%",height:props.value?"75vh":"100vh", display:"flex",justifyContent:"center",alignItems:"center"}} >
          <CSpinner color="primary" />
        </div>
    )
}


