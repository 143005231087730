import { useContext, useEffect, useState } from "react";
import axios from "../../../../axios";
import { Button, InputGroup, Modal } from "react-bootstrap";
import { CButton, CCol, CInput, CLabel, CRow, CSelect } from "@coreui/react";
import ReactDatePicker from "react-datepicker";
import swal from "sweetalert";
import { TestIdContext } from "src/UserContext";
export default function TestPortal(props) {
  const [show, setShow] = useState(false);
  const [disabled,setDisabled]=useState(false)
  const [questions, setQuestions] = useState(props.questions);
  const [testStartDate, setTestStartDate] = useState();
  const [testEndDate, setTestEndDate] = useState();
  const [questionId, SetQuestionId] = useState("");
  const [duration, setDuration] = useState();
  const [testStartDateErr, setTestStartDateErr] = useState(false);
  const [testEndDateErr, setTestEndDateErr] = useState(false);
  const [questionIdErr, setQuestionIdErr] = useState(false);
  const [durationErr, setDurationErr] = useState(false);
  const [testQuestion, setTestQuestion] = useState();
  const [load, setLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setQuestions(props.questions);
  }, [props.questions]);
  const handleClose = () => {
    setTestStartDate();
    setTestEndDate();
    SetQuestionId("");
    setDuration();
    setShow(false);
  };
  const handleShow = () => {
    setShow(true)
  };
  function createTest() {
    if (!testStartDate || !testEndDate || !questionId || !duration) {
      if (!testStartDate) {
        setTestStartDateErr(true);
      } else {
        setTestStartDateErr(false);
      }
      if (!testEndDate) {
        setTestEndDateErr(true);
      } else {
        setTestEndDateErr(false);
      }
      if (!questionId) {
        setQuestionIdErr(true);
      } else {
        setQuestionIdErr(false);
      }
      if (!duration) {
        setDurationErr(true);
      } else {
        setDurationErr(false);
      }
    } else {
      setLoad(true);
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/createTest`,
          {
            questionId: questionId,
            duration,
            testQuestion: testQuestion,
            testEndDate: testEndDate.getTime(),
            testStartDate: testStartDate.getTime(),
            evaluation: props.evaluation.id,
            can_id: props.evaluation,
          },
          {
            headers: { authorization: localStorage.getItem("token") },
          }
        )
        .then((response) => {
          if (response) {
            if (response.data.token) {
              localStorage.setItem("token", "token");
              localStorage.removeItem("refreshToken");
              window.location.reload();
            } else {
              props.setEvaluations((arr) => {
                return arr.filter((obj) => {
                  if (obj.id == response.data[0].id) {
                    obj.testId = response.data[0].testId;
                    obj.Question = response.data[0].Question;
                    return obj;
                  } else {
                    return obj;
                  }
                });
              });
              
              if(props.setTest){
                props.setTest({testId: response.data[0].testId, Question : response.data[0].Question})
              }

              setLoad(false);

              swal({
                text: "   ",
                title: "Test Created",
                icon: "success",

                buttons: false,
                timer: 1500,
              });
              setShow(false);
            }
          }
        });
    }
  }
  function sync() {
    setRefresh(true);
    setDisabled(true)
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/syncQuestions`, {
        headers: { authorization: localStorage.getItem("token") },
      })
      .then((response) => {
        if (response) {
          if (response.data.token) {
            localStorage.setItem("token", "token");
            localStorage.removeItem("refreshToken");
            window.location.reload();
          } else {
            // setQuestions(response.data);
            props.setQuestions(response.data);
            setRefresh(false);
            setDisabled(false)
          }
        }
      });
  }
  return (
    <>
      <Button size="sm" className="bg-gradient-success w-100" onClick={handleShow}>
        Create Test
      </Button>

      <Modal
        centered
        size="md"
        show={show}
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRow>
            <CCol xs="12" className={"my-2"}>
              <CLabel>Select Question</CLabel>
              <div className="d-flex ">
                <CSelect
                  className={"mr-1"}
                  onChange={(e) => {
                    if (e.target.value == "none") {
                        SetQuestionId();
                      setQuestionIdErr(true);
                    } else {
                      let value = JSON.parse(e.target.value);
                      SetQuestionId(value.q);
                      setTestQuestion(value);
                      setQuestionIdErr(false);
                    }
                  }}
                >
                  <option value="none">Select Question</option>
                  {questions.map((obj) => {
                    let text = JSON.stringify({
                      q: obj.Question,
                      title: obj.QuestionTitle,
                    });
                    return <option value={text}>{obj.QuestionTitle}</option>;
                  })}
                </CSelect>
                <CButton
                disabled={disabled}
                  size=""
                  onClick={() => {
                    sync();
                  }}
                  className={"btn btn-primary mt-auto mb-2"}
                >
                  {!refresh ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      class="bi bi-arrow-repeat"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                      <path
                        fill-rule="evenodd"
                        d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                      />
                    </svg>
                  ) : (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CButton>
              </div>

              {questionIdErr && (
                <small style={{ color: "red" }}>Select question</small>
              )}
            </CCol>

            <CCol xs="12" sm="6" md="4">
              <CLabel>Duration</CLabel>
              <InputGroup className="">
                <CInput
                  value={duration}
                  onChange={(e) => {
                    if (parseInt(e.target.value)) {
                      setDuration(parseInt(e.target.value));
                      let num = parseInt(e.target.value);
                      if (num) {
                        setDurationErr(false);
                      } else {
                        setDurationErr(true);
                      }
                    } else {
                      setDuration("");
                      if (parseInt(e.target.value)) {
                        setDurationErr(false);
                      } else {
                        setDurationErr(true);
                      }
                    }
                  }}
                  placeholder="Eg:3"
                  aria-label=""
                  aria-describedby="basic-addon2"
                />
                {duration && (
                  <InputGroup.Text id="basic-addon2">
                    {parseInt(duration) <= 10 ? "Days" : "Mins"}
                  </InputGroup.Text>
                )}
              </InputGroup>
              {durationErr && (
                <small style={{ color: "red" }}>Add duration</small>
              )}
              {/* <CInput
                  value={duration}
                  onChange={(e) => {
                    setDuration(e.target.value);
                  }}
                ></CInput> */}
            </CCol>
            <CCol xs="12" sm="6" md="4" style={{ color: "black" }}>
              <CLabel>Test Start Date</CLabel>
              <ReactDatePicker
                placeholderText="Start Date"
                selected={testStartDate}
                dateFormat="dd-MMM-yyyy"
                onChange={(e) => {
                  setTestStartDate(e);
                  if (e) {
                    setTestStartDateErr(false);
                  } else {
                    setTestStartDateErr(true);
                  }
                }}
                minDate={new Date()}
                maxDate={testEndDate}
                className="form-control"
              ></ReactDatePicker>
              {testStartDateErr && (
                <small style={{ color: "red" }}>Add start date</small>
              )}
            </CCol>
            <CCol xs="12" sm="6" md="4" style={{ color: "black" }}>
              <CLabel>Test End Date</CLabel>
              <ReactDatePicker
                placeholderText="End Date"
                dateFormat="dd-MMM-yyyy"
                selected={testEndDate}
                onChange={(e) => {
                  setTestEndDate(e);
                  if (e) {
                    setTestEndDateErr(false);
                  } else {
                    setTestEndDateErr(true);
                  }
                }}
                minDate={testStartDate ? testStartDate : new Date()}
                className="form-control"
              ></ReactDatePicker>
              {testEndDateErr && (
                <small style={{ color: "red" }}>Add end date</small>
              )}
            </CCol>
          </CRow>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              createTest();
            }}
          >
            Create Test
            {load && (
              <span
                class="spinner-border spinner-border-sm ml-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
