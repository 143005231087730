import React from 'react';
import SeeEvaluations from './views/pages/Applications/SeeEvaluations';

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const EvaluatorDashboard= React.lazy(()=>import('./views/dashboard/EvaluatorDashboard'))
const AddCandidates = React.lazy(()=>import('./views/pages/candidates/AddCandidates'))
const Users = React.lazy(() => import('./views/pages/users/ListUsers'));
const AddUser = React.lazy(() => import('./views/pages/users/AddUser'));
const AddJd =  React.lazy(()=>import('./views/pages/JD/AddJd'));
const  ViewJd = React.lazy(()=>import('./views/pages/JD/ViewJd'));
const JdProfile = React.lazy(()=>import('./views/pages/JD/JdProfile'));
const ViewEvl = React.lazy(()=>import('./views/pages/Evoluation/ViewEvl'));
const onlineInterview = React.lazy(()=>import('./views/pages/Evoluation/ViewEvlOnlineInterview'));
const faceToFace = React.lazy(()=>import('./views/pages/Evoluation/ViewEvlFaceToFace'));
const onlineTest = React.lazy(()=>import('./views/pages/Evoluation/ViewEvlTest'));
const OneEvl = React.lazy(()=>import('./views/pages/Evoluation/OneEvl'));
const Profiles = React.lazy(()=>import('./views/pages/candidates/ViewProfile'))
const DetailProfile = React.lazy(()=>import('./views/pages/candidates/DetailProfile'))
const Offers = React.lazy(()=>import('./views/pages/offers/Offers'))
const Tasks = React.lazy(()=>import('./views/pages/candidates/Tasks/Tasks'))
const ApplyJob=React.lazy(()=>import('./views/pages/OpenPages/ApplyJob'))
const Manage =React.lazy(()=>import('./views/pages/manage/Manage'))
 const LandingPage=React.lazy(()=>import("./views/LandingPage/LandingPage"))
 const Applications=React.lazy(()=>import("./views/pages/Applications/Applications"))
 const Questions=React.lazy(()=>import('./views/pages/JobPortal/Questions'))
 const test=React.lazy(()=>import('./views/pages/JobPortal/Test'))
 const Notificaition=React.lazy(()=>import('./views/pages/Notification/Notification'))

// const Opening = React.lazy(()=>import('./views/pages/OpenPages/Openings'))
let role  = localStorage.getItem("role")
const routes = [

  // { path: '/', exact: true, name: 'Home' },
  { path: '/home', exact: true, name: 'Home' },
  //  { path: '/', exact: true, name: 'Home' ,component:LandingPage},
  
  { path: '/home/dashboard', name: 'Dashboard', component:  localStorage.getItem("role")=='evaluator'?EvaluatorDashboard: Dashboard },
  { path: '/home/pages/AddCandidates', name: 'Add Candidates', component: AddCandidates },
  {path:'/home/pages/addjd',name:'JD',component:AddJd},
  {path:'/home/pages/jds',name:'JD',component:ViewJd},
  {path:'/home/pages/JdProfile',name:'JdProfile',component:JdProfile},
  {path:'/home/pages/telephonic',name:'evl',component:ViewEvl},
  {path:'/home/pages/onlineInterview',name:'evl',component:onlineInterview},
  {path:'/home/pages/face-to-face-interview',name:'evl',component:faceToFace},
  {path:'/home/pages/online-test',name:'evl',component:onlineTest},
  {path:'/home/pages/one_evl',name:'evl',component:OneEvl},
  {path:'/home/pages/profiles',exact:true,name:'profiles',component:Profiles},
  {path:'/home/pages/detailprofile',name:'Detail Profile',component:DetailProfile},
  {path:'/home/pages/offers',name:'Offers',component:Offers},
  {path:'/home/pages/tasks',name:'Tasks',component:Tasks},
  {path:'/home/pages/applications',name:'Applications',component:Applications},
  {path:'/home/pages/see-evaluations',name:'evaluations',component:SeeEvaluations},
  {path:'/home/pages/questions',name:'questions',component:Questions},
  {path:'/home/pages/tests',name:'test',component:test},
  {path:'/home/pages/notification',name:'notification',component:Notificaition},
  // {path:'/openings',name:'ApplyJob',component:ApplyJob},0
  
  {path:'/home/pages/manage',name:'Manage',component:Manage},
  
];

if(role==="admin"){
  routes.push(
  { path: '/home/users', exact: true,  name: 'Users', component: Users },
  { path: '/home/adduser', exact: true, name: 'User Details', component: AddUser }
  )
}
export default routes;
