import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import CreateEvaluation from "../candidates/componants/CreateEvaluation";
import CreateEvaluationList from "../candidates/componants/CreateEvaluationList";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CLabel,
  CRow,
  CSelect,
} from "@coreui/react";
import { Badge, Button, Form, Modal } from "react-bootstrap";
import moment from "moment";
import { HiDocumentText } from "react-icons/hi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TiDeleteOutline } from "react-icons/ti";
import { IoIosSend } from "react-icons/io";
import swal from "sweetalert";
import { UserContext } from "src/UserContext";
import Loading from "../candidates/componants/Loading";
import ApplicationPageRemark from "../Remark/ApplicationPageRemark";
import notification from "../api/notification";
let fields = [
  "application_date_&_time",
  "Application_ID",
  "JD_ID",
  "name",
  "email",
  "phone",
  "resume",
  "status",
  "remark",
];
let level = [
  { level: 1, value: "pending" },
  { level: 2, value: "done" },
  { level: 3, value: "rejected" },
  { level: 4, value: "drop" },
];
export default function SeeEvaluations() {
  const { path, setPath } = useContext(UserContext);
  useEffect(() => {
    setPath("Job Application/View Applications/Detailed View");
  });
  const [evaluations, setEvaluations] = useState([]);
  const [load, setLoad] = useState(true);
  const [normalEvaluator, setEval] = useState([]);
  const [telephonic, setTelephonicEvaluator] = useState([]);
  const [status, setStatus] = useState("");
  const [jd, setJd] = useState({});
  const [application, setApplication] = useState();
  const [notifications, setNotifications] = useState([]);
  const [selectValues, setSelectValues] = useState([
    "pending",
    "done",
    "rejected",
    "drop",
  ]);
  const [selected, setSelected] = useState("");
  const messagesEndRef = useRef(null);

  useEffect(async () => {
    // console.log(window.location.search);
    // console.log("applications id",window.location.search.slice(4,28));
    // console.log("can id",window.location.search.slice(33,57));
    // console.log("jd id",window.location.search.slice(60));
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/getAllEvaluations`,
        {
          can_id: window.location.search.slice(-52, -28),
          jd_id: window.location.search.slice(-24),
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
            can_id: window.location.search.slice(-52, -28),
            jd_id: window.location.search.slice(-24),
          },
        }
      )
      .then((res) => {
        if (res) {
          if (res.data.token) {
            localStorage.removeItem("token");
            window.location.reload();
          } else {
            setEvaluations(res.data);
            setLoad(false);
          }
        }
      });
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/getallEvalutors`, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      })

      .then((res) => {
        if (res.data.token) {
          localStorage.removeItem("token");
          window.location.reload();
        } else {
          let ar = res.data.map((tg) => {
            return {
              value: tg.name,
              label: tg.name,
              id: tg.id,
            };
          });

          setEval(ar);
          //   console.log("getallevaluators",ar);
        }
      });
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/getAllUsers`, {
        headers: { authorization: localStorage.getItem("token") },
      })
      .then((res) => {
        if (res) {
          if (res.data.token) {
            localStorage.setItem("token", "token");
            window.location.reload();
          } else {
            // console.log("getallusers",res.data);
            setTelephonicEvaluator(res.data);
          }
        }
        //change here /getallEvalutors later
      });
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/getjd`,
        { id: window.location.search.slice(-24) },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.token) {
          localStorage.setItem("token", "token");
          window.location.reload();
        } else {
          setJd(res.data);
        }
      });
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/getOneApplication`,
        {
          can_id: window.location.search.slice(-52, -28),
          jd_id: window.location.search.slice(-24),
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
            can_id: window.location.search.slice(-52, -28),
            jd_id: window.location.search.slice(-24),
          },
        }
      )
      .then(async (res) => {
        if (res) {
          if (res.data.token) {
            localStorage.removeItem("token");
            window.location.reload();
          } else {
            setApplication(res.data);
            // let remarkNotification = await notification.getNotification(
            //   `?application=${res.data.id}`
            // );
            axios.get(`${process.env.REACT_APP_API_BASE_URL}/notification/`+res.data.id,{headers: { authorization: localStorage.getItem("token") }}).then((res)=>{
              
              setNotifications(res.data);
            })
            setSelected(res.data.application_status);
            setLoad(false);
          }
        }
      });
  }, []);
  function statusChange(e) {
    let date = Date.now();
    let remark = [
      {
        date: date,
        remark: `Application status changed to ${
          e.target.value === "pending"
            ? "Pending"
            : e.target.value === "done"
            ? "Selected"
            : e.target.value === "drop"
            ? "Dropped"
            : "Rejected"
        }`,

        user: localStorage.getItem("id"),
        userId: localStorage.getItem("id"),
        role: localStorage.getItem("role"),
      },
    ];
    if (e.target.value !== "Select") {
      let obj = {
        id: application.id,
        application_status: e.target.value,
        jd_id: application.jd_id.id,
        candidate: application.candidate.id,
        remark,
      };
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/evaluation/warning`,
          {
            jd_id: application.jd_id.id,
            candidate: application.candidate.id,
            type: e.target.value,
          },
          {
            headers: {
              authorization: localStorage.getItem("token"),
              location: "login",
            },
          }
        )
        .then((res) => {
          if (res.data.exist) {
            setSelectValues([]);
            setSelectValues(["pending", "done", "rejected", "drop"]);
            swal({
              title: "Warning!",
              text: "Make sure all evaluations are completed!",
              timer: 2000,
              icon: "error",
              button: false,
            });
          } else {
            axios
              .post(
                `${process.env.REACT_APP_API_BASE_URL}/jobapplication/update`,
                obj,
                {
                  headers: {
                    authorization: localStorage.getItem("token"),
                  },
                }
              )
              .then((res) => {
                if (res.data.token) {
                  localStorage.setItem("token", "token");
                  window.location.reload();
                } else {
                  axios
                    .post(
                      `${process.env.REACT_APP_API_BASE_URL}/getOneApplication`,
                      {
                        can_id: window.location.search.slice(-52, -28),
                        jd_id: window.location.search.slice(-24),
                      },
                      {
                        headers: {
                          authorization: localStorage.getItem("token"),
                          can_id: window.location.search.slice(-52, -28),
                          jd_id: window.location.search.slice(-24),
                        },
                      }
                    )
                    .then((res) => {
                      if (res) {
                        if (res.data.token) {
                          localStorage.removeItem("token");
                          window.location.reload();
                        } else {
                          setApplication(res.data);

                          setSelected(res.data.application_status);
                          swal({
                            title: "Success!",
                            text: "Status updated!",
                            timer: 1500,
                            icon: "success",
                            button: false,
                          });
                        }
                      }
                    });
                  // axios
                  //   .get(`${process.env.REACT_APP_API_BASE_URL}/getOneUser`, {
                  //     headers: {
                  //       authorization: localStorage.getItem("token"),
                  //       id: res.data[0].candidate_id,
                  //     },
                  //   })
                  //   .then((response) => {
                  //     if (response.data.token) {
                  //       localStorage.removeItem("token");
                  //       window.location.reload();
                  //     } else {
                  //       // console.log(res.data[0].application_status);
                  //       console.log(res.data);
                  //       setApplication(res.data[0]);
                  //       swal({
                  //         title: "Success!",
                  //         text: "Status updated!",
                  //         timer: 1500,
                  //         icon: "success",
                  //         button: false,
                  //       });
                  //     }
                  //   });
                }
              });
          }
        });
    }
  }
  return load ? (
    <Loading value={true}></Loading>
  ) : (
    <>
      <CCard className={"p-0"}>
        <CCardHeader className={"p-0"}>
          <CCardBody className={""}>
            {/* <div style={{ minWidth: "700px" }}>
              <CDataTable
                // items={search[0] || searchFlag ? search : evaluations}
                items={application}
                fields={
                  localStorage.getItem("role") === "evaluator" ? fields : fields
                }
                hover
                bordered
                size="sm"
                pagination
                responsive={true}
                scopedSlots={{
                  "application_date_&_time": (item) => {
                    return (
                      <td>{moment(item.createdAt).format("DD MMM YYYY-LT")}</td>
                    );
                  },
                  Application_ID: (item) => {
                    return <td>{item.custome_ja_id}</td>;
                  },
                  JD_ID: (item) => {
                    return (
                      <td>
                        <ViewJd data={jd}></ViewJd>
                      </td>
                    );
                  },
                  status: (item) => {
                    return (
                      <td>
                        {item.application_status === "pending" ? (
                          <CBadge color="warning">{"Pending"}</CBadge>
                        ) : (
                          <>
                            {item.application_status === "done" ? (
                              <CBadge color="success">{"Selected"}</CBadge>
                            ) : (
                              <CBadge color="danger">{"Rejected"}</CBadge>
                            )}
                          </>
                        )}{" "}
                      </td>
                    );
                  },
                  resume: (item) => {
                    return (
                      <td
                        onClick={() => {
                          if (item.candidate.resume) {
                            window.open(
                              `${process.env.REACT_APP_API_BASE_URL}/documentView/?q=${item.candidate.resume}`,
                              "_blank"
                            );
                          }
                        }}
                        className="text-primary pl-4"
                        style={{
                          cursor: item.resume ? "pointer" : "default",
                        }}
                      >
                        {item.candidate.resume ? (
                          <HiDocumentText size={20}></HiDocumentText>
                        ) : (
                          ""
                        )}
                      </td>
                    );
                  },
                  remark: (item) => {
                    return (
                      <td className="pl-3">
                        <Remark data={item}></Remark>
                      </td>
                    );
                  },
                  name: (item) => {
                    return (
                      <td
                        style={{ cursor: "pointer" }}
                        className="text-primary"
                        // onClick={() => {
                        //   history.push({
                        //     pathname: `/pages/detailprofile`,
                        //     state: item,
                        //   });
                        // }}
                        // `onClick={setauth}`
                      >
                        <Link
                          to={`/home/pages/detailprofile?id=${item.candidate.id}`}
                        >
                          {item.candidate.name}
                        </Link>
                      </td>
                    );
                  },
                  email: (item) => {
                    return (
                      <td
                        style={{ cursor: "pointer" }}
                        className="text-primary"
                        // onClick={() => {
                        //   history.push({
                        //     pathname: `/pages/detailprofile`,
                        //     state: item,
                        //   });
                        // }}
                        // `onClick={setauth}`
                      >
                        <a
                          href="mailto:alendevassy09@gmail.com?subject=SendMail&body=Description"
                          target="_blank"
                        >
                          {item.candidate.email}
                        </a>
                      </td>
                    );
                  },
                  phone: (item) => {
                    return (
                      <td
                        style={{ cursor: "pointer" }}
                        className="text-primary"
                        // onClick={() => {
                        //   history.push({
                        //     pathname: `/pages/detailprofile`,
                        //     state: item,
                        //   });
                        // }}
                        // `onClick={setauth}`
                      >
                        <a href={`tel:${item.candidate.phone}`} target="_blank">
                          {item.candidate.phone}
                        </a>
                      </td>
                    );
                  },
                  status: (item) => {
                    return (
                      <td>
                        <CSelect
                          size="sm"
                          onChange={(e) => {
                            if (e.target.value !== "select") {
                              let obj = {
                                id: item.id,
                                application_status: e.target.value,
                              };
                              axios
                                .post(
                                  `${process.env.REACT_APP_API_BASE_URL}/jobapplication/update`,
                                  obj,
                                  {
                                    headers: {
                                      authorization:
                                        localStorage.getItem("token"),
                                    },
                                  }
                                )
                                .then((res) => {
                                  if (res.data.token) {
                                    localStorage.setItem("token", "token");
                                    window.location.reload();
                                  } else {
                                    console.log(res.data);
                                    axios
                                      .get(
                                        `${process.env.REACT_APP_API_BASE_URL}/getOneUser`,
                                        {
                                          headers: {
                                            authorization:
                                              localStorage.getItem("token"),
                                            id: res.data[0].candidate_id,
                                          },
                                        }
                                      )
                                      .then((response) => {
                                        if (response.data.token) {
                                          localStorage.removeItem("token");
                                          window.location.reload();
                                        } else {
                                          swal({
                                            title: "Success!",
                                            text: "Status updated!",
                                            timer: 1500,
                                            icon: "success",
                                            button: false,
                                          });
                                        }
                                      });
                                  }
                                });
                            }
                          }}
                          custom
                          name="ccmonth"
                          id="ccmonth"
                        >
                          <option value="Select">Select</option>

                          <option
                            value="pending"
                            selected={
                              item.application_status == "pending"
                                ? true
                                : false
                            }
                          >
                            Pending
                          </option>
                          <option
                            value="done"
                            selected={
                              item.application_status == "done" ? true : false
                            }
                          >
                            Selected
                          </option>
                          <option
                            value="rejected"
                            selected={
                              item.application_status == "rejected"
                                ? true
                                : false
                            }
                          >
                            Rejected
                          </option>
                        </CSelect>
                      </td>
                    );
                  },
                }}
              />
            </div> */}
            {application && (
              <CRow>
                <CCol md="3">
                  <CCol>
                    <p className="p-0 m-0" style={{ fontWeight: "bold" }}>
                      Date & time
                    </p>
                  </CCol>
                  <CCol>
                    <h4>
                      <CBadge
                        style={{ fontWeight: "normal" }}
                        className={"p-0"}
                      >
                        {moment(application.createdAt).format("DD MMM YYYY-LT")}
                      </CBadge>
                    </h4>
                  </CCol>
                </CCol>
                <CCol md="3">
                  <CCol>
                    <p className="p-0 m-0" style={{ fontWeight: "bold" }}>
                      Application ID
                    </p>
                  </CCol>
                  <CCol>
                    <h4>
                      <CBadge
                        style={{ fontWeight: "normal" }}
                        className={"p-0"}
                      >
                        {application.custome_ja_id}
                      </CBadge>
                    </h4>
                  </CCol>
                </CCol>
                <CCol md="3">
                  <CCol>
                    <p className="p-0 m-0" style={{ fontWeight: "bold" }}>
                      JD ID
                    </p>
                  </CCol>
                  <CCol>
                    <h4>
                      <CBadge
                        style={{ fontWeight: "normal" }}
                        className={"p-0"}
                      >
                        {/* <ViewJd data={application.jd_id}></ViewJd> */}
                        <Link
                          to={`/home/pages/JdProfile?id=${application.jd_id.id}`}
                        >
                          <td title={application.jd_id.title}>
                            {application.jd_id.custome_jd_id}
                          </td>
                        </Link>
                      </CBadge>
                    </h4>
                  </CCol>
                </CCol>
                <CCol md="3">
                  <CCol>
                    <p className="p-0 m-0" style={{ fontWeight: "bold" }}>
                      Status
                    </p>
                  </CCol>
                  <CCol>
                    <h5>
                      <CBadge
                        style={{ fontWeight: "normal" }}
                        className={"p-0"}
                      >
                        <td>
                          <CSelect
                            size="sm"
                            disabled={
                              localStorage.getItem("role") == "evaluator"
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              if (localStorage.getItem("role") == "admin") {
                                swal({
                                  title: "Are you sure?",
                                  text: `Are you sure about changing status of application ${application.custome_ja_id}`,
                                  type: "warning",
                                  icon: "warning",

                                  dangerMode: true,
                                  buttons: ["No", "Yes"],
                                  dangerMode: true,
                                  closeOnClickOutside: false,
                                }).then((ok) => {
                                  if (ok) {
                                    if (e.target.value != "Select") {
                                      statusChange(e);
                                    }
                                  } else {
                                    setSelectValues([]);
                                    setSelectValues([
                                      "pending",
                                      "done",
                                      "rejected",
                                      "drop",
                                    ]);
                                  }
                                });
                              } else {
                                let value = 0;
                                let defaultValue = 0;
                                for (let i = 0; i < level.length; i++) {
                                  if (level[i].value == e.target.value) {
                                    value = level[i].level;
                                  }
                                  if (
                                    level[i].value ==
                                    application.application_status
                                  ) {
                                    defaultValue = level[i].level;
                                  }
                                }
                                if (value >= defaultValue) {
                                  swal({
                                    title: "Are you sure?",
                                    text: `Are you sure about changing status of application ${application.custome_ja_id}`,
                                    type: "warning",
                                    icon: "warning",

                                    dangerMode: true,
                                    buttons: ["No", "Yes"],
                                    dangerMode: true,
                                    closeOnClickOutside: false,
                                  }).then((ok) => {
                                    if (ok) {
                                      if (e.target.value != "Select") {
                                        statusChange(e);
                                      }
                                    } else {
                                      setSelectValues([]);
                                      setSelectValues([
                                        "pending",
                                        "done",
                                        "rejected",
                                        "drop",
                                      ]);
                                    }
                                  });
                                } else {
                                  setSelectValues([]);
                                  setTimeout(() => {
                                    setSelectValues([
                                      "pending",
                                      "done",
                                      "rejected",
                                      "drop",
                                    ]);
                                  }, 0);

                                  swal({
                                    title: "oops!",
                                    text: "You are not allowed to set the status backward!",
                                    timer: 2000,
                                    icon: "error",
                                    button: false,
                                  });
                                }
                              }
                            }}
                            custom
                            name="ccmonth"
                            id="ccmonth"
                          >
                            {selectValues.map((obj) => {
                              return (
                                <option
                                  value={obj}
                                  selected={
                                    application.application_status == obj
                                      ? true
                                      : false
                                  }
                                >
                                  {obj == "pending"
                                    ? "Pending"
                                    : obj == "done"
                                    ? "Selected"
                                    : obj == "drop"
                                    ? "Drop"
                                    : "Rejected"}
                                </option>
                              );
                            })}

                            {/* 
                            <option
                              value="pending"
                              selected={
                                application.application_status == "pending"
                                  ? true
                                  : false
                              }
                            >
                              Pending
                            </option>
                            <option
                              value="done"
                              selected={
                                application.application_status == "done"
                                  ? true
                                  : false
                              }
                            >
                              Selected
                            </option>
                            <option
                              value="rejected"
                              selected={
                                application.application_status == "rejected"
                                  ? true
                                  : false
                              }
                            >
                              Rejected
                            </option> */}
                          </CSelect>
                        </td>
                      </CBadge>
                    </h5>
                  </CCol>
                </CCol>
                <CCol md="3">
                  <CCol>
                    <p className="p-0 m-0" style={{ fontWeight: "bold" }}>
                      Name
                    </p>
                  </CCol>
                  <CCol>
                    <h4>
                      <CBadge
                        style={{ fontWeight: "normal" }}
                        className={"p-0"}
                      >
                        <td
                          style={{ cursor: "pointer" }}
                          className="text-primary"
                          // onClick={() => {
                          //   history.push({
                          //     pathname: `/pages/detailprofile`,
                          //     state: item,
                          //   });
                          // }}
                          // `onClick={setauth}`
                        >
                          <Link
                            to={`/home/pages/detailprofile?id=${application.candidate.id}`}
                          >
                            {application.candidate.name}
                          </Link>
                        </td>
                      </CBadge>
                    </h4>
                  </CCol>
                </CCol>
                <CCol md="3">
                  <CCol>
                    <p className="p-0 m-0" style={{ fontWeight: "bold" }}>
                      Email
                    </p>
                  </CCol>
                  <CCol>
                    <h4>
                      <CBadge
                        style={{ fontWeight: "normal" }}
                        className={"p-0"}
                      >
                        <td
                          style={{ cursor: "pointer" }}
                          className="text-primary"
                          // onClick={() => {
                          //   history.push({
                          //     pathname: `/pages/detailprofile`,
                          //     state: item,
                          //   });
                          // }}
                          // `onClick={setauth}`
                        >
                          <a
                            href={`mailto:${application.candidate.email}`}
                            target="_blank"
                          >
                            {application.candidate.email}
                          </a>
                        </td>
                      </CBadge>
                    </h4>
                  </CCol>
                </CCol>
                <CCol md="3">
                  <CCol>
                    <p className="p-0 m-0" style={{ fontWeight: "bold" }}>
                      Phone
                    </p>
                  </CCol>
                  <CCol>
                    <h4>
                      <CBadge
                        style={{ fontWeight: "normal" }}
                        className={"p-0"}
                      >
                        <td
                          style={{ cursor: "pointer" }}
                          className="text-primary"
                          // onClick={() => {
                          //   history.push({
                          //     pathname: `/pages/detailprofile`,
                          //     state: item,
                          //   });
                          // }}
                          // `onClick={setauth}`
                        >
                          <a
                            href={`tel:${application.candidate.phone}`}
                            target="_blank"
                          >
                            {application.candidate.phone}
                          </a>
                        </td>
                      </CBadge>
                    </h4>
                  </CCol>
                </CCol>
                <CCol md="3">
                  <CCol>
                    <p className="p-0 m-0" style={{ fontWeight: "bold" }}>
                      Experience
                    </p>
                  </CCol>
                  <CCol>
                    <h4>
                      <CBadge
                        style={{ fontWeight: "normal" }}
                        className={"p-0"}
                      >
                        <td>
                          {application.candidate.experience
                            ? application.candidate.experience > 1
                              ? application.candidate.experience + " years"
                              : application.candidate.experience + " year"
                            : "NA"}
                        </td>
                      </CBadge>
                    </h4>
                  </CCol>
                </CCol>

                <CCol md="3">
                  <CCol>
                    <p className="p-0 m-0" style={{ fontWeight: "bold" }}>
                      Resume
                    </p>
                  </CCol>
                  <CCol>
                    <h4>
                      <CBadge
                        style={{ fontWeight: "normal" }}
                        className={"p-0"}
                      >
                        <td
                          onClick={() => {
                            if (application.candidate.resume) {
                              window.open(
                                `${process.env.REACT_APP_API_BASE_URL}/documentView/?q=${application.candidate.resume}`,
                                "_blank"
                              );
                            }
                          }}
                          className="text-primary"
                          style={{
                            cursor: application.resume ? "pointer" : "default",
                          }}
                        >
                          {application.candidate.resume ? (
                            <HiDocumentText
                              style={{ cursor: "pointer" }}
                              size={20}
                            ></HiDocumentText>
                          ) : (
                            "Not added"
                          )}
                        </td>
                      </CBadge>
                    </h4>
                  </CCol>
                </CCol>
                <CCol md="3">
                  <CCol>
                    <p className="p-0 m-0" style={{ fontWeight: "bold" }}>
                      Remark  
                      {/* {notifications&&<Badge className="text-white bg-danger">{notifications.remarkCount}</Badge>} */}
                    </p>
                  </CCol>
                  <CCol>
                    <h5>
                      <CBadge
                        style={{ fontWeight: "normal" }}
                        className={"p-0"}
                      >
                        <td className="">
                          <ApplicationPageRemark
                            data={application}
                            notification = {notifications}
                            setNotification = {setNotifications}
                          ></ApplicationPageRemark>
                        </td>
                      </CBadge>
                    </h5>
                  </CCol>
                </CCol>
              </CRow>
            )}
          </CCardBody>
        </CCardHeader>
      </CCard>
      <CreateEvaluationList
        data={{
          can_id: window.location.search.slice(33, 57),
          jd_id: window.location.search.slice(61),
          evaluations: evaluations,
          setEvaluations,
          evaluators: normalEvaluator,
          telephonic: telephonic,
          application,
        }}
      ></CreateEvaluationList>
    </>
  );
}
function ViewJd(props) {
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState(props.data.description);
  const [responsibilities, setResponsibilities] = useState(
    props.data.responsibilities
  );
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    setDescription(props.data.description);
  }, [props]);
  // function addRemark(e) {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_BASE_URL}/addEvaluationRemark`,
  //       { remark, id: props.data.id },
  //       {
  //         headers: { authorization: localStorage.getItem("token") },
  //       }
  //     )
  //     .then((response) => {
  //       handleClose();
  //     });
  // }
  return (
    <>
      {/* <Button variant="primary">
            Launch demo modal
          </Button> */}
      <p
        style={{ cursor: "pointer" }}
        className=" my-auto text-primary"
        onClick={handleShow}
      >
        {[props.data.custome_jd_id]}
      </p>
      {/* <svg
          onClick={handleShow}
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-eye-fill"
          viewBox="0 0 16 16"
        >
          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
        </svg> */}
      <Modal centered backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>JD Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Form.Control
              value={remark}
              onChange={(e) => {
                setRemark(e.target.value);
              }}
              as="textarea"
              rows={5}
            /> */}
          <b>Role</b>
          <br></br>
          <label className="ml-1">{props.data.title}</label>
          <br></br>
          <b>Description</b>
          <br></br>
          <label className="ml-1" style={{ whiteSpace: "pre-wrap" }}>
            {description}
          </label>
          <br></br>
          <b>Responsibilities</b>
          <br></br>
          <label className="ml-1" style={{ whiteSpace: "pre-wrap" }}>
            {responsibilities}
          </label>
          <br></br>
          <CRow>
            <CCol className={"col-md-3"}>
              <b>Skills</b>
              <br></br>
              {props.data.skills &&
                props.data.skills.map((obj) => {
                  return <label className="ml-1">{obj.value}</label>;
                })}
            </CCol>
            <CCol className={"col-md-3"}>
              <b>Experience</b>
              <br></br>
              <label className="ml-1">
                {props.data.min_exp}-{props.data.max_exp}
              </label>
            </CCol>
            <CCol className={"col-md-2"}>
              <b>Salary</b>
              <br></br>
              <label className="ml-1">
                {props.data.min_sal}-{props.data.max_sal}
              </label>
            </CCol>
            <CCol className={"col-md-4"}>
              <b>Closing Date</b>
              <br></br>
              <label className="ml-1">
                {moment(props.data.close_date).format("DD-MMM-YYYY")}
              </label>
            </CCol>
          </CRow>
          <br></br>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button
              variant="primary"
              onClick={(e) => {
                addRemark(e);
              }}
            >
              Save Changes
            </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
function Remark(props) {
  const [show, setShow] = useState(false);
  // const [remark, setRemark] = useState(props.data.evaluation_remark);
  const [remark, setRemark] = useState(props.data.application_remark);
  const [text, setText] = useState("");
  const [scroll, setScroll] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  });
  const deleteRemark = (target) => {
    let editedRemark = remark.filter((obj, index) => {
      if (index !== target) {
        return obj;
      }
    });

    setRemark(editedRemark);
    scrollToBottom();
    setScroll(false);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/deleteApplicationRemark`,
        {
          remark: editedRemark,
          id: props.data.id,
          user: localStorage.getItem("id"),
        },
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      )
      .then((response) => {
        setRemark(response.data.application_remark);
        setText("");
        // handleClose();
      });
  };
  function addRemark(newRemark) {
    scrollToBottom();
    setScroll(false);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/addApplicationRemark`,
        {
          remark: [...remark, newRemark],
          id: props.data.id,
          user: localStorage.getItem("id"),
        },
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      )
      .then((response) => {
        setRemark(response.data.application_remark);
        setText("");
        // handleClose();
      });
  }
  return (
    <>
      <svg
        onClick={() => handleShow()}
        style={{
          cursor: "pointer",
          color: remark ? (remark[0] ? "#ef233c" : "#8d99ae") : "#8d99ae",
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="currentColor"
        // className={remark[0] ? "bi text-primary " : "bi bi-card-text"}
        viewBox="0 0 16 16"
      >
        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
        <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
      </svg>
      <Modal centered backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <div className="d-flex">
              <div>
                Remark for{" "}
                <span className="text-primary">
                  {props.data.candidate.name}'s
                </span>{" "}
                Application
              </div>
              {/* <div className="mx-1 text-primary">
                {props.data.can_id.name}'s
              </div>
              <div>Evalution</div> */}
            </div>
            <div className="d-flex">
              {props.data.jd_id.title}-
              <span className="text-primary">
                {props.data.jd_id.custome_jd_id}
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="w-100  flex-column rounded p-1 mb-2"
            style={{
              height: "auto",
              maxHeight: "300px",
              backgroundColor: "#ffffff",
              overflowY: "scroll",
              display: "inline-block",
            }}
          >
            {remark &&
              remark.map((obj, index) => {
                let str2 = "";
                if (obj.role) {
                  const str = obj.role;
                  str2 = str.charAt(0).toUpperCase() + str.slice(1);
                }

                return (
                  <div className="w-100 d-flex">
                    <div
                      className={
                        localStorage.getItem("id") === obj.userId
                          ? "mb-2 px-1 rounded ml-auto"
                          : "mb-2 px-1 rounded"
                      }
                      style={{
                        backgroundColor:
                          localStorage.getItem("id") === obj.userId
                            ? "#e9f5db"
                            : "#dee2ff",
                        minWidth: "70%",

                        maxWidth: "80%",
                      }}
                      key={index}
                    >
                      <div className=" d-flex justify-content-between align-items-center">
                        <small className=" text-primary ">
                          {obj.user}-{str2}
                        </small>

                        {localStorage.getItem("role") === "admin" ? (
                          <TiDeleteOutline
                            className="cross"
                            onClick={() => {
                              deleteRemark(index);
                            }}
                            style={{ cursor: "pointer" }}
                            size={19}
                          ></TiDeleteOutline>
                        ) : localStorage.getItem("id") === obj.userId ? (
                          <TiDeleteOutline
                            className="cross"
                            onClick={() => {
                              deleteRemark(index);
                            }}
                            style={{ cursor: "pointer" }}
                            size={19}
                          ></TiDeleteOutline>
                        ) : (
                          <></>
                        )}
                      </div>
                      <p className=" mb-0 mt-0 pl-1 pt-0 ">
                        <small
                          className="mt-0 pl-1 pt-0"
                          style={{ color: "#231942" }}
                        >
                          {obj.remark}
                        </small>
                      </p>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          width: "auto",
                        }}
                      >
                        <small
                          style={{ fontSize: ".7rem" }}
                          className="mt-0 text-end ml-auto"
                        >
                          {moment(obj.date).startOf().fromNow()}
                        </small>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div
              style={{ width: "100%", height: "2px" }}
              ref={messagesEndRef}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex ">
          <CRow className={" w-100"}>
            <CCol className={"col-10 col-sm-10 p-0 "}>
              <Form.Control
                value={text}
                onChange={(e) => {
                  setText(e.target.value.trimStart());
                }}
                as="textarea"
                rows={1}
              />
            </CCol>
            <CCol className={"col-sm-1"}>
              <Button
                disabled={text ? false : true}
                variant="primary"
                onClick={(e) => {
                  setScroll(true);
                  let date = Date.now();
                  if (remark[0]) {
                    setRemark((obj) => {
                      return [
                        ...obj,
                        {
                          date: date,
                          remark: text,
                          user: localStorage.getItem("id"),
                          userId: localStorage.getItem("id"),
                          role: localStorage.getItem("role"),
                        },
                      ];
                    });
                  } else {
                    setRemark([
                      {
                        date: date,
                        remark: text,
                        user: localStorage.getItem("id"),
                        userId: localStorage.getItem("id"),
                        role: localStorage.getItem("role"),
                      },
                    ]);
                  }

                  addRemark({
                    date: date,
                    remark: text,
                    user: localStorage.getItem("id"),
                    userId: localStorage.getItem("id"),
                    role: localStorage.getItem("role"),
                  });
                }}
              >
                <IoIosSend size={22}></IoIosSend>
              </Button>
            </CCol>
          </CRow>
        </Modal.Footer>
      </Modal>
    </>
  );
}
