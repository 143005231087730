import { CCol, CRow } from "@coreui/react";
import "./style.css";
import flutter from "./images/flutter.png";
import react from "./images/react.png";
import firebase from "./images/firebase.png";
import node from "./images/node.png";
import mongo from "./images/mongo.png";
import salesforce from "./images/salesforce.svg";
import design from "./images/design.svg";
import android from "./images/android.svg";
import ios from "./images/ios.png";
import aws from "./images/aws.png";
import jquery from "./images/jquery.png";
import eleventy from "./images/eleventry.svg";
import whatsapp from "./images/whatsapp.svg";
import mysql from "./images/mysql.svg";
export default function Technologies() {
  return (
    <CRow className={"bg-primary text-center mx-auto  pb-3"}>
      <CCol xs="12" className={"mt-4 mb-3"}>
        <p className="h3 Goldman">Technologies at Mobigic</p>
      </CCol>
      <div className="mx-auto  grid-container ">
        <div className="bg-white item pt-4 px-4 item">
          <img src={flutter} alt="" />
          <p className="Nunito text-dark font-weight-bold mt-3">Flutter</p>
        </div>
        <div className="bg-white pt-4 px-4 item">
          <img src={react} alt="" />
          <p className="Nunito text-dark font-weight-bold mt-3">React JS</p>
        </div>
        <div className="bg-white pt-4 px-4 item">
          <img src={firebase} alt="" />
          <p className="Nunito text-dark font-weight-bold mt-3">Firebase</p>
        </div>
        <div className="bg-white pt-4 font-weight-bold px-4 item">
          <img src={node} alt="" />
          <p className="Nunito text-dark font-weight-bold mt-3">Node JS</p>
        </div>
        <div className="bg-white pt-4 px-4 item">
          <img src={mongo} alt="" />
          <p className="Nunito text-dark font-weight-bold mt-3">MongoDB</p>
        </div>
        <div className="bg-white pt-4  px-4 item">
          <img src={salesforce} alt="" />
          <p className="Nunito text-dark font-weight-bold mt-3">Salesforce</p>
        </div>
        <div className="bg-white pt-4 px-4 item">
          <img src={design} alt="" />
          <p className="Nunito text-dark font-weight-bold mt-3">UI/UX Design</p>
        </div>
      {/* </div>
      <div className="mx-auto bg-dark px-1   grid-container  mt-3 mb-3"> */}
        <div className="bg-white  item pt-4  px-4  item">
          <img src={android} alt="" />
          <p className="Nunito text-dark font-weight-bold mt-3">Android</p>
        </div>
        <div className="bg-white pt-4  px-4  item">
          <img src={ios} alt="" />
          <p className="Nunito text-dark font-weight-bold mt-3">Apple IOS</p>
        </div>
        <div className="bg-white pt-4  px-4  item">
          <img src={aws} alt="" />
          <p className="Nunito text-dark font-weight-bold mt-3">AWS</p>
        </div>
        <div className="bg-white pt-4  px-4  item">
          <img src={jquery} alt="" />
          <p className="Nunito text-dark font-weight-bold mt-3">JQuery</p>
        </div>
        <div className="bg-white pt-4  px-4  item">
          <img src={eleventy} alt="" />
          <p className="Nunito text-dark font-weight-bold mt-3">Eleventy</p>
        </div>
        <div className="bg-white pt-4  px-4  item">
          <img src={whatsapp} alt="" />
          <p className="Nunito text-dark font-weight-bold mt-3"> Services</p>
        </div>
        <div className="bg-white pt-4  px-4  item">
          <img src={mysql} alt="" />
          <p className="Nunito text-dark font-weight-bold mt-3">MySQL</p>
        </div>
      </div>
    </CRow>
  );
}
